import React from 'react';
import {
  POPUP_TYPE_GRID,
  POPUP_TYPE_TEMPLATE
} from '../constants';
import InfoTemplate from './InfoTemplate';
import InfoGrid from './InfoGrid';
import { useInfoPopup } from '../hooks/useInfoPopup';

const Info = props => {
  const { id,
    gpudb,
    infoLayers,
    radius,
    width,
    coordinate,
    columns,
    calculatedField,
    calculatedFieldName,
    close,
  } = props;

  const {
    pageNumber,
    displayedPageNumber,
    records,
    selectedLayer,
    localInfoLayers,
    isLoading,
    handleLayerSelected,
    decrementPageNumber,
    incrementPageNumber,
    setDisplayedPageNumber,
    setPageNumber,
  } = useInfoPopup({
    gpudb,
    infoLayers,
    radius,
    coordinate,
    columns,
    calculatedField,
    calculatedFieldName,
  });

  return (
    <div
      id={id}
      style={{
        height: '223px',
        width: `${width / 1.2 - 0}px`,
        backgroundColor: '#ffffff',
        border: '1px solid #cccccc',
        padding: '10px',
        fontSize: '11px',
        borderRadius: '5px',
        zIndex: 1000,
      }}
    >
      {infoLayers && selectedLayer?.popupType === POPUP_TYPE_GRID && (
        <InfoGrid
          pageNumber={pageNumber}
          displayedPageNumber={displayedPageNumber}
          records={records}
          selectedLayer={selectedLayer}
          localInfoLayers={localInfoLayers}
          isLoading={isLoading}
          handleLayerSelected={handleLayerSelected}
          decrementPageNumber={decrementPageNumber}
          incrementPageNumber={incrementPageNumber}
          setDisplayedPageNumber={setDisplayedPageNumber}
          setPageNumber={setPageNumber}
          width={width}
          coordinate={coordinate}
          close={close}
        />
      )}
      {infoLayers && selectedLayer?.popupType === POPUP_TYPE_TEMPLATE && (
        <InfoTemplate
          pageNumber={pageNumber}
          displayedPageNumber={displayedPageNumber}
          records={records}
          selectedLayer={selectedLayer}
          localInfoLayers={localInfoLayers}
          isLoading={isLoading}
          handleLayerSelected={handleLayerSelected}
          decrementPageNumber={decrementPageNumber}
          incrementPageNumber={incrementPageNumber}
          setDisplayedPageNumber={setDisplayedPageNumber}
          setPageNumber={setPageNumber}
          width={width}
          coordinate={coordinate}
          popupTemplate={selectedLayer?.popupTemplate}
          close={close}
        />
      )}
    </div>
  );
};

export default Info;
