// Plan is to make this into an adapter pattern for Tableau and other BI tools
// List private properties;
let instance;

let widgetColumns = '{}';

class TableauService {
    constructor() {
        if (instance) {
            throw new Error("You can only create one instance!");
        }
        instance = this;
    };

    setWidgetColumns(newWidgetColumns) {
        widgetColumns = JSON.stringify(newWidgetColumns);
    }

    getWidgetColumns() {
        // TODO: make sure to return an object that cannot be edited
        return JSON.parse(widgetColumns);
    }

}

const tableauService = Object.freeze(new TableauService());
export default tableauService;