// Plan is to make this into an adapter pattern for Tableau and other BI tools
import tableauService from './TableauService';

export const HOST_TABLEAU = 'HOST_TABLEAU';

// List private properties;
let hostApp = HOST_TABLEAU;
let instance;
let hostAppOptions = [HOST_TABLEAU];

const HOST_TO_SERVICE_MAPPING = {
    [HOST_TABLEAU]: tableauService
};

class HostService {
    constructor(host) {
        if (instance) {
            throw new Error("You can only create one instance!");
        }
        if (hostAppOptions.indexOf(host) < 0) {
            throw new Error(`${host} is not one of the host application options ${hostAppOptions.toString()}`);
        }
        hostApp = HOST_TO_SERVICE_MAPPING[host];
        instance = this;
    }

    getHostAPP() {
        return hostApp;
    }

    setWidgetColumns(widgetColumns) {
        hostApp.setWidgetColumns(widgetColumns);
    }

    getWidgetColumns() {
        return hostApp.getWidgetColumns();
    }
}

export const hostService = Object.freeze(new HostService(HOST_TABLEAU));