import moment from "moment";
import * as d3 from "d3";
import { TABLEAU_AGGREGATIONS_LIST as excludeAggList, KINETICA_CALC_FIELD_TABLE } from "./constants";

export const d3Format = (format, value) => {
  return d3.format(format)(value);
};

export const isNumValueSame = (num1, num2) => {
  return num1 === num2 || (isNaN(num1) && isNaN(num2));
};

export const isDuplicateTableauFilter = (filter1, filter2) => {
  // Check if the two filters are the same but maybe coming from different worksheets
  if (
    filter1.fieldName === filter2.fieldName
    && filter1.filterType === filter2.filterType
    && filter1.isAllSelected === filter2.isAllSelected
    && filter1.isExcludeMode === filter2.isExcludeMode
    && isNumValueSame(filter1.maxValue, filter2.maxValue)
    && isNumValueSame(filter1.minValue, filter2.minValue)
    && filter1.appliedValues && filter1.appliedValues && filter1.appliedValues.toString() === filter2.appliedValues.toString()
  ) {
    return true
  }

  return false;
};

export const convertTableColumnName = (columnName) => {
  let nameNoParen = columnName;
  let functionName;
  let isBinned = false;
  let hasAggFunction = false;

  // Remove Action prefix.  Tableau has something called an action which applies filters based on interaction with the UI.  
  // We need more research on this.  For now, just ignore the Action prefix
  if (nameNoParen.match(/^Action+\s*\(/)) {
    nameNoParen = nameNoParen.substring(nameNoParen.indexOf('(') + 1, nameNoParen.lastIndexOf(')'));
    const ignoreAction = columnName.substring(0, columnName.indexOf('('));
    console.log('Removing Action keyword from column name ', ignoreAction);
  }

  const rawsqlPrefixes = [
    'RAWSQL_BOOL', 'RAWSQL_DATE', 'RAWSQL_DATETIME', 'RAWSQL_INT', 'RAWSQL_REAL', 'RAWSQL_SPATIAL', 'RAWSQL_STR',
    'RAWSQLAGG_DATE', 'RAWSQLAGG_DATETIME', 'RAWSQLAGG_INT', 'RAWSQLAGG_REAL', 'RAWSQLAGG_STR',
  ];
  for (let i = 0; i < rawsqlPrefixes.length; i++) {
    const prefix = rawsqlPrefixes[i];
    if (nameNoParen.startsWith(prefix)) {
      // there could be more than 1 column value here, but for now only process the first one
      const regex = /\[([^]*)\]/gm;
      const values = [];
      let match;
      while ((match = regex.exec(nameNoParen)) !== null) {
        values.push(match[1]);
      }
      console.log('rawsqlPrefix: extracted columns: using first value of array: ', values);
      nameNoParen = values[0].trim();
      break;
    }
  }

  // remove any tableau suffixes
  const tableauSuffixes = ['(bin)', '(aggregated)', '(copy)', '(generated)', '(measure)'];
  tableauSuffixes.forEach(suffix => {
    if (nameNoParen.includes(suffix)) {
      if (suffix == '(bin)') {
        isBinned = true;
      }
      nameNoParen = nameNoParen.replace(suffix, '');
      nameNoParen = nameNoParen.trim();
    }
  });

  if (nameNoParen.match(/^[A-Z]+\(/)) {
    // Note: Changed this from cur.column to nameNoParen
    functionName = nameNoParen.substring(0, nameNoParen.indexOf('('));
    nameNoParen = nameNoParen.substring(nameNoParen.indexOf('(') + 1, nameNoParen.indexOf(')')).toLowerCase();
    // functionName = cur.column.substring(0, cur.column.indexOf('('));
    if (excludeAggList.includes(functionName)) {
      hasAggFunction = true;
    }
  } else {
    nameNoParen = nameNoParen.toLowerCase();
  }

  // remove any additional name in parenthesis
  if (nameNoParen.toLowerCase().includes('(')) {
    nameNoParen = nameNoParen.toLowerCase().substring(0, nameNoParen.toLowerCase().indexOf('('));
    nameNoParen = nameNoParen.trim();
  }

  return {
    nameNoParen,
    additionalInfo: {
      functionName,
      isBinned,
      hasAggFunction
    }
  };
};

export const base64ArrayBuffer = arrayBuffer => {
  let base64 = '';
  const encodings =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  const bytes = new Uint8Array(arrayBuffer);
  const byteLength = bytes.byteLength;
  const byteRemainder = byteLength % 5;
  const mainLength = byteLength - byteRemainder;
  let a;
  let b;
  let c;
  let d;
  let chunk;
  // Main loop deals with bytes in chunks of 3
  for (let i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];
    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
    d = chunk & 63; // 63       = 2^6 - 1
    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
  }
  // Deal with the remaining bytes and padding
  if (byteRemainder === 1) {
    chunk = bytes[mainLength];
    a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2
    // Set the 4 least significant bits to zero
    b = (chunk & 3) << 4; // 3   = 2^2 - 1
    base64 += encodings[a] + encodings[b] + '==';
  } else if (byteRemainder === 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];
    a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4
    // Set the 2 least significant bits to zero
    c = (chunk & 15) << 2; // 15    = 2^4 - 1
    base64 += encodings[a] + encodings[b] + encodings[c] + '=';
  }
  return base64;
};

export const buildExpression = filterArrays => {
  console.log('buildExpression: filterArrays:', filterArrays);
  const conditions = filterArrays.map(filterObj => {
    const [key, value] = Object.entries(filterObj)[0];
    const sFilter = value;
    const numericTypes = ['int', 'float', 'currency', 'percent', 'date', 'date-time', 'duration'];
    const dateTimeFunctions = ['YEAR', 'QUARTER', 'MONTH', 'DAY', 'HOUR', 'MINUTE', 'SECOND'];

    const { exteriorExpression, include, exclude, type, binned, isCalculatedField, includeNullValues, isAllSelected, isExcludeMode } = sFilter;
    if (exteriorExpression === undefined || (excludeAggList.indexOf(exteriorExpression) === -1)) {
      let keyVal = key;
      let values = include;

      const _exteriorExpression = exteriorExpression ? exteriorExpression : '';
      // range filter on date-time and number
      if (numericTypes.indexOf(type) > -1 && !isCalculatedField) {
        console.log('buildExpression: range filter on date-time and number: ', sFilter);

        // include
        if (include && include.length > 0) {
          if (typeof include[0] === 'string' && type !== 'date-time') {
            console.log('buildExpression: string / not date-time: ', sFilter);

            // values are formatted strings so the exterior expression needs to be applied
            keyVal = _exteriorExpression.length > 0 ? `${_exteriorExpression}(${key})` : `${key}`;
            values = sFilter.include.map(value => {
              return _exteriorExpression.length > 0 ? `${exteriorExpression}('${value}')` : `${value}`;
            });

            const inExp = Array.from(new Set(values))
              .map(value => {
                return (sFilter.type &&
                  sFilter.type === 'string') ||
                  typeof value === 'string'
                  ? `${value}`
                  : value;
              })
              .join(',');
            return `${keyVal} in (${inExp})`;

          } else if (typeof include[0] === 'string' && type === 'date-time') {
            console.log('buildExpression: string / date-time: ', sFilter);

            // apply the date times as a range
            const dateTimes = sFilter.include.map(value => {
              return moment(value)
            });

            const minDateTime = moment.min(dateTimes);
            const maxDateTime = moment.max(dateTimes);
            const dateFormat = 'YYYY-MM-DD HH:mm:ss';

            return `${key} >= '${minDateTime.utc().format(dateFormat)}' and ${key} <= '${maxDateTime.utc().format(dateFormat)}'`;
          } else if (dateTimeFunctions.indexOf(_exteriorExpression) > -1) { 
            // has YEAR, QUARTER, MONTH, DAY, HOUR, MINUTE, SECOND
            console.log('buildExpression: date-time function: ', sFilter);
            const { YEAR, QUARTER, MONTH, DAY, HOUR, MINUTE, SECOND } = sFilter;
            let exp = '';
            if (YEAR) {
              exp += `YEAR(${key}) >= ${Math.min(...YEAR)} and YEAR(${key}) <= ${Math.max(...YEAR)} and `;
            }
            if (QUARTER) {
              exp += `QUARTER(${key}) >= ${Math.min(...QUARTER)} and QUARTER(${key}) <= ${Math.max(...QUARTER)} and `;
            }
            if (MONTH) {
              exp += `MONTH(${key}) >= ${Math.min(...MONTH)} and MONTH(${key}) <= ${Math.max(...MONTH)} and `;
            }
            if (DAY) {
              exp += `DAY(${key}) >= ${Math.min(...DAY)} and DAY(${key}) <= ${Math.max(...DAY)} and `;
            }
            if (HOUR) {
              exp += `HOUR(${key}) >= ${Math.min(...HOUR)} and HOUR(${key}) <= ${Math.max(...HOUR)} and `;
            }
            if (MINUTE) {
              exp += `MINUTE(${key}) >= ${Math.min(...MINUTE)} and MINUTE(${key}) <= ${Math.max(...MINUTE)} and `;
            }
            if (SECOND) {
              exp += `SECOND(${key}) >= ${Math.min(...SECOND)} and SECOND(${key}) <= ${Math.max(...SECOND)} and `;
            }
            return exp.substring(0, exp.length - 4);
          
          } else if (numericTypes.indexOf(type) > -1) {
            console.log('buildExpression: number: ', sFilter);
            if (!exteriorExpression || (excludeAggList.indexOf(exteriorExpression) === -1)) {
              if ( binned && binned.length > 0) {
                let _expr = '';
                const range = Math.abs(Math.abs(binned[1]) - Math.abs(binned[0]));
                _expr = `IF(${key}/${range} < 0 AND CAST(${key}/${range}, int) != ${key}/${range}, CAST(${key}/${range}, int) - 1, CAST(${key}/${range}, int) - 0 ) = ${binned[2]}`;
                return _expr;
              } else {
                // apply as a range (include is already in numeric order)
                let min = Math.min(...include);
                let max = Math.max(...include);

                let minMaxExp = '';
                min = isNaN(min) ? include[0] : min;
                max = isNaN(max) ? include[include.length - 1] : max;

                if (!isNaN(min)) {
                  minMaxExp = `${key} >= ${min}`;
                }

                if (!isNaN(max)) {
                  minMaxExp = minMaxExp != '' ? `${minMaxExp} and ${key} <= ${max}` : `${key} <= ${max}`;
                }

                if (includeNullValues && minMaxExp != '') {
                  // If range filter is applied add null as an option
                  minMaxExp = `(${minMaxExp} OR ${key} IS NULL)`;
                }

                return minMaxExp;
                // return `${key} >= ${min} and ${key} <= ${max}`;
              }
            } else {
              console.log("buildExpression: ignoring exteriorExpression for number type: ", sFilter);
            }
          }
        } else if (include?.length < 1 && isExcludeMode === false) {
          return '1 = 2';
        }

        // exclude
        if (exclude && exclude.length > 0) {
          if (typeof exclude[0] === 'string' && type !== 'date-time') {
            // values are formatted strings so the exterior expression needs to be applied
            keyVal = _exteriorExpression.length > 0 ? `${_exteriorExpression}(${key})` : `${key}`;
            values = sFilter.exclude.map(value => {
              return _exteriorExpression.length > 0 ? `${exteriorExpression}('${value}')` : `${value}`;
            });

            const inExp = Array.from(new Set(values))
              .map(value => {
                return (sFilter.type &&
                  sFilter.type === 'string') ||
                  typeof value === 'string'
                  ? `${value}`
                  : value;
              })
              .join(',');
            return `${keyVal} not in (${inExp})`;

          } else if (typeof exclude[0] === 'string' && type === 'date-time') {
            // apply the date times as a range
            const dateTimes = sFilter.exclude.map(value => {
              return moment(value)
            });

            const minDateTime = moment.min(dateTimes);
            const maxDateTime = moment.max(dateTimes);
            const dateFormat = 'YYYY-MM-DD HH:mm:ss';

            return `${key} < '${minDateTime.utc().format(dateFormat)}' and ${key} > '${maxDateTime.utc().format(dateFormat)}'`;
          } else if (dateTimeFunctions.indexOf(_exteriorExpression) > -1) { 
            // has YEAR, QUARTER, MONTH, DAY, HOUR, MINUTE, SECOND
            console.log('buildExpression: date-time function: ', sFilter);
            const { YEAR, QUARTER, MONTH, DAY, HOUR, MINUTE, SECOND } = sFilter;
            let exp = '';
            if (YEAR) {
              exp += `YEAR(${key}) < ${Math.min(...YEAR)} and YEAR(${key}) > ${Math.max(...YEAR)} and `;
            }
            if (QUARTER) {
              exp += `QUARTER(${key}) < ${Math.min(...QUARTER)} and QUARTER(${key}) > ${Math.max(...QUARTER)} and `;
            }
            if (MONTH) {
              exp += `MONTH(${key}) < ${Math.min(...MONTH)} and MONTH(${key}) > ${Math.max(...MONTH)} and `;
            }
            if (DAY) {
              exp += `DAY(${key}) < ${Math.min(...DAY)} and DAY(${key}) > ${Math.max(...DAY)} and `;
            }
            if (HOUR) {
              exp += `HOUR(${key}) < ${Math.min(...HOUR)} and HOUR(${key}) > ${Math.max(...HOUR)} and `;
            }
            if (MINUTE) {
              exp += `MINUTE(${key}) < ${Math.min(...MINUTE)} and MINUTE(${key}) > ${Math.max(...MINUTE)} and `;
            }
            if (SECOND) {
              exp += `SECOND(${key}) < ${Math.min(...SECOND)} and SECOND(${key}) > ${Math.max(...SECOND)} and `;
            }
            return exp.substring(0, exp.length - 4);
          
          } else if (numericTypes.indexOf(type) > -1) {
            if (!exteriorExpression || (excludeAggList.indexOf(exteriorExpression) === -1)) {
              if ( binned && binned.length > 0) {
                let _expr = '';
                const range = Math.abs(Math.abs(binned[1]) - Math.abs(binned[0]));
                _expr = `IF(${key}/${range} < 0 AND CAST(${key}/${range}, int) != ${key}/${range}, CAST(${key}/${range}, int) - 1, CAST(${key}/${range}, int) - 0 ) != ${binned[2]}`;
                return _expr;
              } else {
                // apply as a range (include is already in numeric order)
                const min = Math.min(...include);
                const max = Math.max(...include);
                return `${key} < ${min} and ${key} >= ${max}`;
              }
            } else {
              console.log("buildExpression: ignoring exteriorExpression for number type: ", sFilter);
            }
          }
        }
      } 
      
      // filter by value on all other types
      else {
        keyVal = _exteriorExpression.length > 0 ? `${_exteriorExpression}(${key})` : `${key}`;  

        // include
        if (include && include.length > 0) {
          if (isCalculatedField) {
            let includeKeys = new Set(sFilter.include);
            const { minValue, maxValue, includeNullValues } = sFilter;
            console.log('buildExpression minValue, maxValue:', minValue, maxValue);
            if (minValue != null && maxValue != null) {
              // this is a range filter so we need to grab all they keys within the range
              const f2 = sFilter;
              const includeRangeKeys = [];
              f2.sql.forEach(sqlEntry => {
                Object.keys(sqlEntry).forEach(sqlEntryKey => {
                  if (minValue <= sqlEntryKey && sqlEntryKey <= maxValue) {
                    console.log('buildExpression: sqlEntry, key in range:', sqlEntryKey);
                    includeRangeKeys.push(sqlEntryKey);
                  }
                });
              });
              includeKeys = includeRangeKeys;
            }
            let inExp = Array.from(includeKeys)
              .map(sqlKeyVal => {
                let sqlKey = isNaN(sqlKeyVal) ? "NULL" : sqlKeyVal;
                const f = sFilter;
                console.log('buildExpression:  sqlKey: ', sqlKey, ' f: ', f);
                let sqlEntries = [];
                f.sql.forEach(sqlEntry => {
                  console.log('buildExpression: sqlEntry: ', sqlEntry, ' sqlKey: ', sqlKey, Object.keys(sqlEntry));
                  if (sqlEntry[sqlKey]) {
                    sqlEntries.push(sqlEntry[sqlKey]);
                    console.log('buildExpression: pushing sqlEntry: ', sqlEntry[sqlKey], ' sqlEntries: ', sqlEntries);
                  }
                });
                console.log('buildExpression: sqlEntries: ', sqlEntries);
                let _sql;
                if (sqlEntries.length > 1) {
                  _sql = sqlEntries.join(' OR ');
                } else if (sqlEntries.length === 1) {
                  _sql = sqlEntries[0];
                }
                console.log('buildExpression: returning _sql: ', _sql);
                return _sql;
              })
              .join(' OR ');

            // handle case if inExp is empty then none of the keys matched up so we have exclude everything
            console.log('buildExpression: returning inExp: ', inExp);
            // includeNullValues
            if (includeNullValues == true) {
              console.log('buildExpression: has includeNullValues: ', includeNullValues);

              // If range filter is applied and null values are allowed, do the negation of all the sql values
              const exExp = Array.from(new Set(sFilter.sql))
                .map(sqlArrEntry => {
                  let _sql = Object.values(sqlArrEntry).join(' OR ');
                return _sql;
              })
              .join(' OR ');

              inExp = inExp === '' ? `NOT(${exExp})` : `(${inExp}) OR (NOT(${exExp}))`
              console.log('buildExpression: has includeNullValues expression: ', inExp);
            }

            return `(${inExp})`;

          } else {
            const hasInNull = Array.from(new Set(sFilter.include)).filter(val => val == '%null%')?.length > 0;
            const inExp = Array.from(new Set(sFilter.include))
              .filter(val => val != '%null%')
              .map(value => {
                return (sFilter.type &&
                  sFilter.type === 'string') ||
                  typeof value === 'string'
                  ? `'${value}'`
                  : value;
              })
              .join(',');
            const kInExp = inExp === '' ? '' : `${keyVal} in (${inExp})`;
            const kNullExp = hasInNull ? `${keyVal} IS NULL` : '';
            return `(${kInExp} ${kInExp != '' && kNullExp != '' ? ' OR ' : ''} ${kNullExp})`
          }
        } else if (include?.length < 1 && isExcludeMode === false) {
          return '1 = 2';
        }
        
        // exclude
        if (exclude && exclude.length > 0) {
          if (isCalculatedField) {
            const exExp = Array.from(new Set(sFilter.exclude))
              .map(sqlKey => {
                const f = sFilter;
                let sqlEntries = [];
                f.sql.forEach(sqlEntry => {
                  if (sqlEntry[sqlKey]) {
                    sqlEntries.push(sqlEntry[sqlKey]);
                  }
                });
                let _sql;
                if (sqlEntries.length > 1) {
                  _sql = sqlEntries.join(' OR ');
                } else if (sqlEntries.length === 1) {
                  _sql = sqlEntries[0];
                }
                return _sql;
              })
              .join(' OR ');
            return `NOT (${exExp})`;

          } else {
            const exExp = Array.from(new Set(sFilter.exclude))
              .map(value => {
                return (sFilter.type &&
                  sFilter.type === 'string') ||
                  typeof value === 'string'
                  ? `'${value}'`
                  : value;
              })
              .join(',');
            return `${key} not in (${exExp})`;
          }
        }
      }
    }
    return '';
  });
  return conditions.filter(cond => cond !== '').join(' and ');
};

export const thousands_separators = num => {
  const num_parts = num.toString().split('.');
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return num_parts.join('.');
};

export const handleFilters = updates => {
  return updates
    .filter(update => {
      if (update.appliedValues != null) {
        return update.appliedValues.length > 0 || (update.appliedValues.length === 0 && !update.isAllSelected);
      }

      if (update.filterType === 'range' && (update.maxValue != null || update.minValue != null)) {
        return true;
      }

      return false;
    })
    .map(update => {
      if (update.appliedValues != null) {
        return update.isExcludeMode
        ? {
            nameNoParen: update.nameNoParen,
            additionalInfo: { ...update.additionalInfo },
            column: update.fieldName,
            includeNullValues: update.includeNullValues,
            isAllSelected: update.isAllSelected,
            isExcludeMode: update.isExcludeMode,
            exclude:
              update.appliedValues.length > 0
                ? update.appliedValues.map(item => {
                    return item.value;
                  })
                : [],
          }
        : {
            nameNoParen: update.nameNoParen,
            additionalInfo: { ...update.additionalInfo },
            column: update.fieldName,
            includeNullValues: update.includeNullValues,
            isAllSelected: update.isAllSelected,
            isExcludeMode: update.isExcludeMode,
            include:
              update.appliedValues.length > 0
                ? update.appliedValues.map(item => {
                    return item.value;
                  })
                : [],
          };
      } else {
        // should be a range filter
        return {
          nameNoParen: update.nameNoParen,
          additionalInfo: { ...update.additionalInfo },
          column: update.fieldName,
          includeNullValues: update.includeNullValues,
          isAllSelected: update.isAllSelected,
          isExcludeMode: update.isExcludeMode,
          include: [update.minValue.value, update.maxValue.value],
          minValue: update.minValue.value,
          maxValue: update.maxValue.value,
          type: 'int',
        }
      }

    });
};

export const getTextWidth = (text, style) => {
  var canvas = document.createElement('canvas');
  var context = canvas.getContext('2d');
  context.font = style || '11px "Roboto", Arial, sans-serif';
  var metrics = context.measureText(text);
  return metrics.width;
};

export const isDateOrTimeBased = (column, allColumns) => {
  const columnConfig = allColumns.find((c) => c.name === column);
  if (typeof columnConfig?.type === 'string') {
      if ((columnConfig?.type === 'timestamp' || columnConfig?.type === 'date' || 
       columnConfig?.type === 'time' || columnConfig?.type === 'datetime') ||
      
      (columnConfig?.type === 'long' && columnConfig?.properties.includes('timestamp')) ||
      (columnConfig?.type === 'string' && columnConfig?.properties.includes('date')) ||
      (columnConfig?.type === 'string' && columnConfig?.properties.includes('time')) ||
      (columnConfig?.type === 'string' && columnConfig?.properties.includes('datetime'))) {
          return true;
      }
  } else if (Array.isArray(columnConfig?.type) && columnConfig?.type.length > 0) {
      if ((columnConfig?.type.includes('timestamp') || columnConfig?.type === 'date' || 
       columnConfig?.type.includes('time') || columnConfig?.type === 'datetime') ||
      
      (columnConfig?.type.includes('long') && columnConfig?.properties.includes('timestamp')) ||
      (columnConfig?.type.includes('string') && columnConfig?.properties.includes('date')) ||
      (columnConfig?.type.includes('string') && columnConfig?.properties.includes('time')) ||
      (columnConfig?.type.includes('string') && columnConfig?.properties.includes('datetime'))) {
          return true;
      }
  }
  return false;
};

export const saveKineticaCalculatedField = async (gpudb, user, workbookname, calculatedFields, saveToTable) => {
  try {
    const filterExpression = `workbookname = '${workbookname}' AND user = '${user}'`;
    const result = await gpudb.get_records_from_collection(saveToTable, 0, 10, { "return_record_ids": "true", "expression": filterExpression });
    if (result?.data && result?.data?.length > 0) {
      const newValuesMap = { workbookname, user, calculatedFields: JSON.stringify(calculatedFields), lastModified: (new Date().getTime()).toString(), lastAccessed: (new Date().getTime()).toString() };
      await gpudb.update_records(saveToTable, [filterExpression], [newValuesMap], [], {});
    } else if (result?.data && result?.data?.length === 0) {
      const newValuesMapInsert = { workbookname, user, calculatedFields: JSON.stringify(calculatedFields), lastModified: new Date().getTime(), lastAccessed: new Date().getTime() };
      await gpudb.insert_records(saveToTable, [newValuesMapInsert], {});
    }

    return { status: 'success' };

  } catch (ex) {
    console.log('Error while trying to save calculated fields', KINETICA_CALC_FIELD_TABLE, user, workbookname, calculatedFields, ex);
  }
  return { status: 'error' };

};

export const loadKineticaCalculatedField = async (gpudb, user, workbookname, saveToTable) => {
  try {
    const filterExpression = `workbookname = '${workbookname}' AND user = '${user}'`;
    const result = await gpudb.get_records_from_collection(saveToTable, 0, 10, { "return_record_ids": "true", "expression": filterExpression });
    const newValuesMap = { lastAccessed: (new Date().getTime()).toString() };

    console.log('get saved calculated fields results', result);
    if (result?.data && result?.data?.length > 0) {
      await gpudb.update_records(saveToTable, [filterExpression], [newValuesMap], [], {});
      return result.data.length > 0 ? result.data[0].calculatedFields : null;
    }

  } catch (ex) {
    console.log('Error while trying to load calculated fields', KINETICA_CALC_FIELD_TABLE, user, workbookname, ex);
  }
  return null;
};