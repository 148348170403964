import React, { useEffect, useState, useCallback } from 'react';
import DataGrid from 'react-data-grid';
import {
    CaretLeftFill,
    CaretRightFill,
    X,
} from 'react-bootstrap-icons';
import {
    Dropdown,
    DropdownButton,
    Form,
} from 'react-bootstrap';
import {
    INFO_MODAL_FETCH_LIMIT,
    INFO_MODAL_MAX_CONTENT_LENGTH,
    INFO_MODAL_MAX_COLUMNS_TO_SHOW,
} from '../constants';
import { getTextWidth } from '../util';
import GPUdbHelper from '../lib/GPUdbHelper';

export const useInfoPopup = function ({ gpudb, infoLayers, radius, coordinate, columns, calculatedField, calculatedFieldName }) {

    const [pageNumber, setPageNumber] = useState(1);
    const [displayedPageNumber, setDisplayedPageNumber] = useState(1);
    const [records, setRecords] = useState(undefined);
    const [selectedLayer, setSelectedLayer] = useState(null);
    const [localInfoLayers, setLocalInfoLayers] = useState(infoLayers?.map(lyr => lyr));
    const [isLoading, setIsLoading] = useState(false);

    useEffect(
        _ => {
            if (!isNaN(displayedPageNumber)
                && displayedPageNumber >= 1
                && displayedPageNumber <= (parseInt(records?.total_number_of_records / INFO_MODAL_FETCH_LIMIT) + 1)) {
                setPageNumber(displayedPageNumber);
            }
        }, [displayedPageNumber]
    );

    useEffect(
        () => {
            const layerWithData = infoLayers?.find(lyr => !lyr.queriedData || lyr.qualified_view_name != null);

            setLocalInfoLayers(infoLayers?.map(lyr => lyr));
            setSelectedLayer(layerWithData);
            setPageNumber(1);
            setDisplayedPageNumber(1);
            setRecords(undefined);
        },
        [infoLayers]
    );


    const handleLayerSelected = useCallback(async (layerId) => {
        const newSelectedLayerIndex = localInfoLayers.findIndex((lyr) => lyr.id === layerId);
        const newSelectedLayer = localInfoLayers[newSelectedLayerIndex];

        if (selectedLayer.id === layerId) {
            // If same layer is selected, return to prevent unnecessary API calls
            return;
        }

        if (newSelectedLayer.queriedData == null) {
            // Find data around the click first since the layer has not been queried yet
            setIsLoading(true);
            let newLayer = await GPUdbHelper.infoPopupHelper.checkSingleMapLayerDataAtLocation(gpudb, newSelectedLayer, coordinate[0], coordinate[1], radius);
            let newLocalLayers = localInfoLayers.map((lyr, lyrIndex) => lyrIndex === newSelectedLayerIndex ? newLayer : lyr);
            setLocalInfoLayers(newLocalLayers);
            setIsLoading(false);
            setSelectedLayer(newLayer);
            setPageNumber(1);
            setDisplayedPageNumber(1);
            setRecords(undefined);
        } else {
            setSelectedLayer(newSelectedLayer);
            setPageNumber(1);
            setDisplayedPageNumber(1);
            setRecords(undefined);
        }
    }, [localInfoLayers, radius, selectedLayer]);

    useEffect(
        _ => {
            if (selectedLayer) {
                let table = selectedLayer.qualified_view_name;

                async function fetchData(table, calculatedField, columns) {

                    // convert the data to be consumable by the DataGrid
                    function transformData(data) {
                        let newData = [];
                        let maxRows = 0;
                        if (data.column_1?.length > 0) {
                            maxRows = data.column_1.length;
                        }
                        for (let i = 0; i < maxRows; i++) {
                            let newRow = {};
                            let column_names = data.column_headers;
                            for (let j = 0; j < column_names.length; j++) {
                                let colName = 'column_' + (j + 1);
                                newRow[column_names[j]] = data[colName][i];
                            }
                            newData.push(newRow);
                        }
                        return newData;
                    }

                    // trim records to N columns
                    function keepToMaxColumns(records, maxCols) {
                        return records.data.map(item => {
                            let newItem = {};
                            let count = 0;
                            for (let key in item) {
                                if (count < maxCols) {
                                    newItem[key] = item[key];
                                }
                                count++;
                            }
                            return newItem;
                        });
                    }


                    if (calculatedField === null) {
                        const records = await gpudb.get_records(
                            table,
                            (pageNumber - 1) * INFO_MODAL_FETCH_LIMIT,
                            INFO_MODAL_FETCH_LIMIT,
                            {}
                        );
                        records.data = keepToMaxColumns(records, INFO_MODAL_MAX_COLUMNS_TO_SHOW);
                        setRecords(records);

                    } else {
                        const column_names = columns.map(column => column.name);

                        // insert calculated field into column_names' first position
                        column_names.unshift(calculatedField);

                        const records = await gpudb.get_records_by_column(
                            table,
                            column_names,
                            (pageNumber - 1) * INFO_MODAL_FETCH_LIMIT,
                            INFO_MODAL_FETCH_LIMIT,
                            {}
                        );

                        if (calculatedFieldName !== null) {
                            // remove first item from records.column_headers
                            records.data.column_headers.shift();
                            // insert calculated field name into column_names' first position
                            //records.data.column_headers.unshift(calculatedFieldName + ' (' + calculatedField + ')');
                            records.data.column_headers.unshift(calculatedFieldName);
                        }

                        const newData = transformData(records.data);
                        //console.log('InfoGrid newData', newData);

                        records.data = newData;
                        records.data = keepToMaxColumns(records, INFO_MODAL_MAX_COLUMNS_TO_SHOW);
                        setRecords(records);
                    }
                }

                if (gpudb && table) {
                    fetchData(table, calculatedField, columns);
                } else {
                    setRecords(undefined);
                }
            }
        },
        [gpudb, selectedLayer, calculatedField, columns, calculatedFieldName, pageNumber]
    );

    const decrementPageNumber = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
            setDisplayedPageNumber(pageNumber - 1);
        }
    };

    const incrementPageNumber = () => {
        if (pageNumber < parseInt(records?.total_number_of_records / INFO_MODAL_FETCH_LIMIT) + 1) {
            setPageNumber(pageNumber + 1);
            setDisplayedPageNumber(pageNumber + 1);
        }
    };

    return {
        pageNumber,
        displayedPageNumber,
        records,
        selectedLayer,
        localInfoLayers,
        isLoading,
        handleLayerSelected,
        decrementPageNumber,
        incrementPageNumber,
        setDisplayedPageNumber,
        setPageNumber,
    };
}