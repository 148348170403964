import React from 'react';
import DataGrid from 'react-data-grid';
import {
  CaretLeftFill,
  CaretRightFill,
  X,
} from 'react-bootstrap-icons';
import {
  Dropdown,
  DropdownButton,
  Form,
} from 'react-bootstrap';
import {
  INFO_MODAL_FETCH_LIMIT,
  INFO_MODAL_MAX_CONTENT_LENGTH,
} from '../constants';
import { getTextWidth } from '../util';

const InfoGrid = (props) => {
  const {
    pageNumber,
    displayedPageNumber,
    records,
    selectedLayer,
    localInfoLayers,
    isLoading,
    handleLayerSelected,
    decrementPageNumber,
    incrementPageNumber,
    setDisplayedPageNumber,
    setPageNumber,
    coordinate,
    close
  } = props;

  const truncateIfString = (value, length) => {
    return typeof value === 'string' &&
      value.length > INFO_MODAL_MAX_CONTENT_LENGTH
      ? `${value.slice(0, length)}...`
      : value;
  };

  const convertIfTimestamp = value => {
    return typeof value === 'number' && value.toString().length === 13
      ? new Date(value).toUTCString()
      : value;
  };

  const getMaxWidthRequired = (column, pad) => {
    const columnWidth = getTextWidth(
      truncateIfString(column, INFO_MODAL_MAX_CONTENT_LENGTH)
    );
    const maxTextWidth = records
      ? records.data.reduce((acc, cur) => {
        const textWidth = getTextWidth(
          convertIfTimestamp(
            truncateIfString(cur[column], INFO_MODAL_MAX_CONTENT_LENGTH)
          )
        );
        acc = Math.max(acc, textWidth);
        return acc;
      }, 50)
      : 50;
    return Math.max(columnWidth, maxTextWidth) + pad;
  };

  const layerSelectOptsDropDown = localInfoLayers?.map((lyr) => (<Dropdown.Item
    key={lyr.id}
    onClick={() => { handleLayerSelected(lyr.id); }}
    value={lyr.id}>
    {lyr.label}
  </Dropdown.Item>));

  const loadingPanel = isLoading ? <div style={{
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    opacity: '0.7',
    backgroundColor: '#fff',
    zIndex: '99',
    fontWeight: "bold",
  }}>Loading....</div> : null;

  return (
    <div>
      {loadingPanel}

      {!isLoading && selectedLayer && (
        <>
          <div style={{ marginBottom: '5px' }}>
            <div className="info-layer-selection">
              <DropdownButton
                style={{ width: "100%", minWidth: "200px", maxWidth: "400px" }}
                id="select-info-layer"
                variant="dark"
                title={selectedLayer.label}
                size="sm"
              >
                {layerSelectOptsDropDown}
              </DropdownButton>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>

              {records && <div id="displaying">
                Displaying <strong>{(pageNumber * INFO_MODAL_FETCH_LIMIT) - INFO_MODAL_FETCH_LIMIT + 1} to {Math.min((pageNumber * INFO_MODAL_FETCH_LIMIT), records?.total_number_of_records)}</strong> of{' '}
                <strong>{records?.total_number_of_records}</strong> records
              </div>}

              <span style={{ float: 'right' }}>
                <X
                  onClick={_ => close()}
                  size={18}
                  style={{
                    position: 'absolute',
                    top: '7px',
                    right: '5px',
                    color: '#ca2c92',
                    cursor: 'pointer',
                  }}
                />
              </span>

              {records && <div
                id="page_control"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <CaretLeftFill
                  onClick={_ => {
                    decrementPageNumber();
                  }}
                />
                <Form.Control
                  as="input"
                  min="1"
                  max={parseInt(records?.total_number_of_records / INFO_MODAL_FETCH_LIMIT) + 1}
                  size="sm"
                  style={{ height: "14px", width: "60px", textAlign: "center" }}
                  value={displayedPageNumber}
                  onChange={e => {
                    setDisplayedPageNumber(e.target.value);
                  }}
                />
                <CaretRightFill
                  onClick={_ => {
                    incrementPageNumber();
                  }}
                />
              </div>}

              {coordinate && (
                <span style={{ float: 'right', marginRight: '16px' }}>
                  Coordinate:{' '}
                  <strong>
                    {coordinate.map(val => val.toPrecision(8)).join(', ')}
                  </strong>
                </span>
              )}
            </div>

          </div>
          {records == null && <div className="info_grid info_grid_no_records">
            No Records
          </div>}
          {records?.data && records?.data[0] && <div className="info_grid">
            <DataGrid
              columns={Object.keys(records.data[0]).map(column => ({
                key: column,
                name: column.toUpperCase(),
                width: getMaxWidthRequired(column, 60),
                formatter: props => {
                  return (
                    <>
                      {convertIfTimestamp(
                        truncateIfString(
                          props.row[column],
                          INFO_MODAL_MAX_CONTENT_LENGTH
                        )
                      )}
                    </>
                  );
                },
              }))}
              rows={records.data}
              //height={183}
              rowHeight={14}
              summaryRowHeight={123}
              style={{ fontSize: '12px' }}
            />
          </div>}
        </>
      )}
    </div>
  );
};

export default InfoGrid;
