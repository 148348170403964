import { fromExtent } from 'ol/geom/Polygon';
import { unByKey } from 'ol/Observable';
import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { Button, Badge } from 'react-bootstrap';
import { Stack } from 'react-bootstrap-icons';
import { debounce } from 'debounce';
import OlMap from 'ol/Map';
import OlView from 'ol/View';
import { defaults as DefaultControls, ZoomSlider, ScaleLine } from 'ol/control';
import OlLayerTile from 'ol/layer/Tile';
import Group from 'ol/layer/Group';
import OlSourceOSM from 'ol/source/OSM';
import OlSourceXYZ from 'ol/source/XYZ';
import OlOverlay from 'ol/Overlay';
import { transform, transformExtent } from 'ol/proj';
import { fromLonLat } from 'ol/proj';
import { WKT } from 'ol/format';
import GPUdb from '../lib/GPUdb';
import Info from './Info';
import MapSettings from './MapSettings';
import { DrawButton, DrawLayer, OlDrawer } from './Draw';
import { ViewportButton } from './ViewportButton';
import { CalcFieldUploadButton } from './CalcFieldUploadButton';
import MissingParameters from './MissingParameters';
import { KWmsOlLayer } from './KWmsOlLayer';
import {
  buildExpression,
  thousands_separators,
  handleFilters,
  saveKineticaCalculatedField,
  loadKineticaCalculatedField,
  convertTableColumnName,
  isDuplicateTableauFilter,
} from '../util';
import {
  DEFAULT_COLORMAP,
  DEFAULT_FILL_COLOR,
  DEFAULT_BORDER_COLOR,
  DEFAULT_BLUR_RADIUS,
  DEFAULT_POINT_SIZE,
  DEFAULT_OPACITY,
  MAP_CLICK_PIXEL_RADIUS,
  DEMO_MODE_DISABLED,
  DEMO_MODE_ENABLED,
  DEMO_DATASOURCES,
  FILTERING_MODE_SELECTION,
  FILTERING_MODE_FILTER,
  OUTBOUND_RANGE_FILTER_ENABLED,
  MAPBOX_ACCESS_TOKEN,
  CURSOR_INFO,
  CURSOR_FREEHAND_DRAW,
  TABLEAU_AGGREGATIONS_LIST,
  DEFAULT_MIN_ZOOM,
  DEFAULT_MAX_ZOOM,
  LAYER_TYPES,
  DEFAULT_POPUP_TEMPLATE,
  DEFAULT_POPUP_TYPE,
  CALC_FIELD_STORAGE_TYPES,
  KINETICA_CALC_FIELD_TABLE,
} from '../constants';
import 'ol/ol.css';
import '../styles/Main.css';

import { hostService } from '../lib/HostService';
import GPUdbHelper from '../lib/GPUdbHelper';
import Geocoder from 'ol-geocoder';
import { LayersPanel } from './LayersPanel';
import { TwbContext } from './TwbContext';

// Declare this so our linter knows that tableau is a global object
/* global tableau */

let unregisterHandlerFunctions = [];
let multimapUnregisterHandlerFunctions = [];
let savedWktValue = '';
let ignoreResetCount = 0;
const cachedSettings = [];
let globalView = '';

// Creating a special debounce function with a global state timer as there seems to be to an issue when
// using the normal debounce with tableau getFilterAsync? See: https://community.tableau.com/s/question/0D54T00000C5lXtSAJ/javascript-api-marks-selection-triggers-twice
// There seems to be an issue referencing the local function variable, the workaround is to use a global variable
// filterTimer for the getFilterAsync.  Do not use this as a general debounce as you might need a separate state
// for each function
let filterTimer;
const tabelauFilterDebounce = (mainFunction, delay) => {
  return function (...args) {
    clearTimeout(filterTimer);

    filterTimer = setTimeout(() => {
      mainFunction(...args);
    }, delay);
  };
};

function Map(props) {

  const { reloadConfig } = props;
  const reloadConfigRef = useRef(reloadConfig);

  const [areParametersMissing, setAreParametersMissing] = useState(false);
  const [selectedDatasource, setSelectedDatasource] = useState(undefined);
  const [datasources, setDatasources] = useState([]);
  const [endpoint, setEndpoint] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [cursorMode, setCursorMode] = useState(CURSOR_INFO);
  const [kTableList, setKTableList] = useState([]);
  const [hasLoadedKineticaTables, setHasLoadedKineticaTables] = useState(false);

  // index: -1 => no layer - only show table. 0 => base layer. 1 => first layer
  const [selectedLayer, setSelectedLayer] = useState({op:'none', index: 0});

  const [mapLayers, setMapLayers] = useState([{
    id: '0000',
    layerType: LAYER_TYPES.K_WMS_BASE_LAYER.value,
    label: 'Base Layer',
    visible: true,
    maxZoom: DEFAULT_MAX_ZOOM,
    minZoom: DEFAULT_MIN_ZOOM,
    opacity: DEFAULT_OPACITY,
    enablePopup: true,
    popupTemplate: DEFAULT_POPUP_TEMPLATE,
    popupType: DEFAULT_POPUP_TYPE,
    kineticaSettings: {
      baseTable: '',
      view: '',

      renderType: 'raster',
      dataType: 'point',

      longitude: '',
      latitude: '',
      wkt: '',

      blurRadius: DEFAULT_BLUR_RADIUS,
      heatmapAttr: null,
      pointSize: DEFAULT_POINT_SIZE,

      colormap: DEFAULT_COLORMAP,
      fillColor: DEFAULT_FILL_COLOR,
      borderColor: DEFAULT_BORDER_COLOR,

      width: window.innerWidth,

      cbStyleOptions: {
        column: '',
        binCount: 4,
        allStyleColors: null,
        allStyleRanges: null,
        allStyleShapes: null,
        allStyleSizes: null,
        colorRamp: ['fd191f', 'e06100', 'bc8500', '949e00', '6aaf35', '34b36a', '029496', '00b0b2', '00a2d0', '008ef5'],
        otherColor: '888888',
        selectedTheme: 'Spectral',
        orderClasses: null,
      },
    },
  }]);

  const [width, setWidth] = useState(window.innerWidth);

  // TODO: Remove drawLayer state as the state never changes
  const [drawLayer, setDrawLayer] = useState(DrawLayer);
  const [drawType, setDrawType] = useState('');
  const [drawUndo, setDrawUndo] = useState(false);
  const [drawnFeatures, setDrawnFeatures] = useState([]);

  const [filterByViewportMode, setFilterByViewportMode] = useState(false);
  const [viewportKey, setViewportKey] = useState(null);
  const [handleMapClickKey, setHandleMapClickKey] = useState(null);


  const [gpudb, setGpudb] = useState(undefined);
  const [filters, setFilters] = useState([]);
  const [basemapUrl, setBasemapUrl] = useState('');
  const [demoMode, setDemoMode] = useState(DEMO_MODE_DISABLED);
  const [center, setCenter] = useState(fromLonLat([0, 0]));
  const [zoom, setZoom] = useState(2);
  const [count, setCount] = useState(-1);
  const [filteringMode, setFilteringMode] = useState(FILTERING_MODE_SELECTION);
  const [schemaListAsString, setSchemaListAsString] = useState('ki_home');
  const [storeCalculatedFieldInKinetica, setStoreCalculatedFieldInKinetica] = useState(CALC_FIELD_STORAGE_TYPES['NO_STORAGE'].value);
  const [calcFieldsSaveName, setCalcFieldsSaveName] = useState('');
  const [hasLoadedCalFields, setHasLoadedCalFields] = useState(false);

  const [infoCoordinate, setInfoCoordinate] = useState(undefined);
  const [infoLayers, setInfoLayers] = useState(undefined);
  const [infoRadius, setInfoRadius] = useState(undefined);

  const [saving, setSaving] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});
  const [showLayersPanel, setShowLayersPanel] = useState(true);
  const [mapboxApiKey, setMapboxApiKey] = useState(MAPBOX_ACCESS_TOKEN);
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);
  const [binRanges, setBinRanges] = useState({});
  const [calculatedFields, setCalculatedFields] = useState({});
  const [twbDetails, setTwbDetails] = useState({});
  const [twbFile, setTwbFile] = useState(null);
  const inputFile = useRef(null);
  const [sqlBase, setSqlBase] = useState(undefined);
  const [layersFilterText, setLayersFilterText] = useState('');
  const [hasRegisteredForMultimap, setHasRegisteredForMultimap] = useState(false);
  const [setResetCount, setSetResetCount] = useState(0);
  const updateParameterDelay = useRef();

  const componentName = 'map';

  const [map] = useState(
    new OlMap({
      zoomControl: false,
      target: undefined,
      layers: [
        new OlLayerTile({
          name: 'OSM',
          source: new OlSourceOSM({
            crossOrigin: 'anonymous',
            wrapX: true,
            noWrap: false,
          }),
        }),
        drawLayer
      ],
      overlays: [],
      controls: DefaultControls().extend([
        new Geocoder('nominatim', {
          provider: 'osm',
          lang: 'en-US',
          placeholder: 'Search for location',
          limit: 5,
          debug: false,
          autoComplete: true,
          keepOpen: false,
        }),
        new ZoomSlider(), 
        new ScaleLine()
      ]),
      pixelRatio: 1.0,
      view: new OlView({
        center,
        zoom,
      }),
    })
  );

  window.onresize = _ => {
    setWidth(window.innerWidth);
  };

  const handleUploadTwbFile = () => {
    inputFile.current.click();
  };

  const asyncSaveSettings = (callback) => {
    if(!saving && tableau.extensions.settings) {
      setSaving(true);
      const now = new Date().getTime();

      // copy the cached settings and clear the cache
      const cachedSettingsCopy = cachedSettings.slice();
      const numSettings = cachedSettings.length;
      cachedSettings.length = 0;

      while (cachedSettingsCopy.length > 0) {
        const setting = cachedSettingsCopy.shift();
        const name = setting.name;
        const value = setting.value;
        const op = setting.op;

        console.log('name: ' + name + ', value: ' + value + ', op: ' + op );
        if (op === 'set') {
          tableau.extensions.settings.set(name, value);
        } else if (op === 'erase') {
          tableau.extensions.settings.erase(name);
        }
      }

      tableau.extensions.settings
        .saveAsync()
        .then(() => {
          console.log('saved settings: ' +numSettings+ ' in ' + (new Date().getTime() - now) + 'ms');
          setSaving(false);
          // if callback is defined, call it
          if (callback) {
            callback();
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  const asyncSaveViewport = debounce((center, zoom) => {
    cachedSettings.push({
      name: 'center',
      value: JSON.stringify(center),
      op: 'set'});
    cachedSettings.push({
      name: 'zoom',
      value: zoom,
      op: 'set'});
    console.log('asyncSaveViewport --> center: ' + center + ', zoom: ' + zoom);
  }, 1000);

  const asyncSaveFilterByViewportMode = debounce((mode) => {
    cachedSettings.push({
      name: 'filterByViewportMode',
      value: mode,
      op: 'set'});
    console.log('asyncSaveFilterByViewportMode --> mode: ' + mode);
  }, 1000);

  const setError = (componentName, errorMessage, func) => {
    if (errorMessage === '') {
      delete errorMsg[componentName];
      console.log('setError --> ' + componentName + ': CLEARED');
    } else {
      console.error('setError --> ' + componentName + ': ' + errorMessage);
      if (func) {
        setErrorMsg(prevState => ({
          ...prevState,
          [componentName]: {text: errorMessage},
          ['func']: func
        }));
      } else {
        setErrorMsg(prevState => ({
          ...prevState,
          [componentName]: {text: errorMessage}
        }));
      }
    }
  }
  
  const filterByViewportCallback = debounce((viewExtent) => {
    const { longitude, latitude, wkt, view } = mapLayers[0].kineticaSettings;
    if (!selectedDatasource || ((longitude === '' || latitude === '') && !wkt)) {
      return;
    }
    console.log('updateMapFeatures --> filterByViewPortcallback', view);
    updateMapFeatures(viewExtent, globalView);// change this from global view???(RP)
  }, 300);

  const updateParameters = debounce(async (parameterValues) => {
    const { worksheets } = tableau.extensions.dashboardContent.dashboard;
    for (const [parameterName, parameterValue] of Object.entries(parameterValues)) {
      worksheets.forEach(async worksheet => {
        const param = await worksheet.findParameterAsync(parameterName);
        if (param) {
          console.log("updateParameters --> parameter found: " + parameterName + ", value: " + parameterValue)
          param.changeValueAsync(parameterValue);
        } else {
          if (parameterName !== 'multimap') {
            console.error('updateParameters --> parameter not found: ' + parameterName);
          }
        }
      });
    }

    // This may be able to help us get rid of using Custom SQL
    // const dataSources = await tableau.extensions?.workbook?.getAllDataSourcesAsync()
    // if (dataSources) {
    //   dataSources.forEach(async dataSource => {
    //     dataSource.refreshAsync();
    //   });
    // }

  }, updateParameterDelay.current);

  const mapMoveHandler = useCallback(evt => {
    const map = evt.map;
    const viewExtent = transformExtent(map.getView().calculateExtent(map.getSize()), 'EPSG:3857', 'EPSG:4326');
    console.log('viewExtent: ' + viewExtent);
    filterByViewportCallback(viewExtent);
  }, [filterByViewportCallback]);

  const saveSettings = settings => {
    Object.keys(settings).forEach(key => {
      if (settings[key]) {
        cachedSettings.push({
          name: key,
          value: settings[key],
          op: 'set'});
      } else {
        cachedSettings.push({
          name: key,
          value: '',
          op: 'erase'});
      }
    });

    asyncSaveSettings();
  };

  const getClickRadius = useCallback(
    extent => {
      const mapWidth = extent[2] - extent[0];
      return mapWidth * (MAP_CLICK_PIXEL_RADIUS / width);
    },
    [width]
  );

  const buildGeoDistExpr = (lon, lat, lonCol, latCol, radius) => {
    return `GEODIST(${lon}, ${lat}, ${lonCol}, ${latCol}) <= ${radius}`;
  };

  const buildSTXYDWithinExpr = (lon, lat, wktCol, radius) => {
    return `STXY_DWITHIN(${lon}, ${lat}, ${wktCol}, ${radius}, 1) = 1`;
  };

  const geoFilter = useCallback(
    async function geoFilter(table, expression) {
      return await gpudb.filter(table, '', expression, {
        ttl: '20',
        create_temp_table: 'true',
      });
    },
    [gpudb]
  );

  const openInfo = useCallback(
    coordinate => {
      if (map && map.getOverlayById('info')) {
        const mapExtent = map.getView().calculateExtent(map.getSize());
        const center = map.getView().getCenter();
        const x = center[0];
        const y = (mapExtent[3] - mapExtent[1]) * 0.5 + mapExtent[1];
        map.getOverlayById('info').setPosition([x,y]);
      }
    },
    [map]
  );

  const closeInfo = useCallback(
    _ => {
      if (map && map.getOverlayById('info')) {
        map.getOverlayById('info').setPosition(null);
        setInfoLayers(null);
      }
    },
    [map]
  );

  // TODO(Multilayer): handle click for multiple layers
  const handleMapClick = useCallback(
    async function (evt) {
      if (cursorMode === CURSOR_FREEHAND_DRAW) {
        console.log('IN handleDrawMapClick2: ', evt);
        console.log('drawcomplete handler should handle this instead');
        return;
      }
      const { longitude, latitude, wkt, dataType, view, baseTable } = mapLayers[0].kineticaSettings;

      if (gpudb && selectedDatasource && map && (baseTable || view)) {
        // Determine coordinate of click and compute relative radius
        const extent = evt.map.getView().calculateExtent(evt.map.getSize());
        const coords = transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
        const lon = coords[0];
        const lat = coords[1];
        const radius = getClickRadius(extent);

        // Build filter expression based on column info
        let expression = '';
        if (dataType === 'point') {
          expression = buildGeoDistExpr(lon, lat, longitude, latitude, radius);
        } else if (dataType === 'geo') {
          expression = buildSTXYDWithinExpr(lon, lat, wkt, radius);
        }

        // Use click to determine area to filter for recods
        const { table } = selectedDatasource;
        // const baseTable = `${table.schema}.${table.name}`;

        try {
          setError(componentName, '');
          const currentZoom = evt.map.getView().getZoom();
          const visibleLayers = mapLayers?.filter((lyr) => lyr.enablePopup && lyr.visible && lyr.maxZoom >= currentZoom && lyr.minZoom <= currentZoom).map((lyr) => {
            // view, latColumn, lonColumn, wktColumn
            const { label, kineticaSettings, id, popupType, popupTemplate } = lyr;
            const { longitude: lonColumn, latitude: latColumn, wkt: wktColumn, view, baseTable } = kineticaSettings;
            const foundKTable = kTableList.find(tableInfo => tableInfo.full_name === baseTable);
            return {
              id,
              label,
              view: view || baseTable,
              baseTable,
              lonColumn,
              latColumn,
              wktColumn,
              columns: foundKTable?.columns,
              popupTemplate,
              popupType,
            };
          });
          let clickResults = await GPUdbHelper.infoPopupHelper.checkMapLayerDataAtLocation(gpudb, visibleLayers, lon, lat, radius);
          const hasClickData = clickResults?.filter((cResult) => cResult.queriedData && cResult.qualified_view_name != null)?.length > 0;

          // Manage display of info overlay
          if (!hasClickData) {
              closeInfo();
          } else {
            setInfoLayers(clickResults);
            openInfo(evt.coordinate);
            setInfoCoordinate(coords);
            setInfoRadius(radius);
          }
        } catch (error) {
          setError(componentName, error);
          closeInfo();
        }
      }
    },
    [
      gpudb,
      selectedDatasource,
      map,
      cursorMode,
      drawType,
      mapLayers,
      getClickRadius,
      geoFilter,
      openInfo,
      closeInfo,
      kTableList,
    ]
  );
  useEffect(() => {
    if (map) {
      if (handleMapClickKey) {
        unByKey(handleMapClickKey);
      }
      setHandleMapClickKey(map.on('singleclick', handleMapClick));
    }
  }, [map, handleMapClick]);

  // TODO(multilayer): seems like no changes needed
  const updateCount = useCallback(
    layer => {
      if (endpoint && username && password) {
        if (selectedDatasource === undefined) {
          setCount(-1);
          return;
        }
        const options = {
          timeout: 60000,
          username: username,
          password: password,
        };

        const { apiUrl } = selectedDatasource;
        const gpudb = new GPUdb(apiUrl ?? endpoint, options);
        const { table } = selectedDatasource;
        const fullTable = layer || `${table.schema}.${table.name}`;

        setError(componentName, '');
        gpudb.show_table(fullTable, { get_sizes: 'true' }, (err, data) => {
          if (!err) {
            setCount(data.sizes);
          } else {
            setError(componentName, err);
            setCount(-1);
          }
        });
      } else {
        setError(componentName, 'No endpoint, username, or password');
        setCount(-1);
      }
    },
    [selectedDatasource, endpoint, username, password]
  );

  // when filterByViewportMode changes
  useEffect(() => {
    asyncSaveFilterByViewportMode(filterByViewportMode);
  }, [filterByViewportMode]);

  const saveSqlBase = (stmt) => {
    const stopIndex = stmt.toUpperCase().indexOf('WHERE');
    const base = stopIndex > 0 ? stmt.substring(0, stopIndex).replace(/\r?\n|\r/g, "") : stmt;
    console.log('setSqlBase: ', base);
    setSqlBase(base);
  };

  const handleTwbFileProcessed = (twbData) => {
    const { twbDetails, calculatedFields } = twbData;
    setTwbDetails(twbDetails);
    setCalculatedFields(calculatedFields);
    if (storeCalculatedFieldInKinetica === CALC_FIELD_STORAGE_TYPES.KINETICA_TABLE_STORAGE.value) {
      saveKineticaCalculatedField(gpudb, username, calcFieldsSaveName, calculatedFields, KINETICA_CALC_FIELD_TABLE);
    }
  };

  const registerMultimapParameterListener = () => {

    const { latitude, longitude, wkt } = mapLayers[0].kineticaSettings;

    if (
      !(gpudb &&
      ((longitude !== '' && latitude !== '') || wkt !== ''))
    ) {
      console.error('registerMultimapParameterListener (incomplete data): ', gpudb, longitude, latitude, wkt);
      return;
    }

    tableau.extensions.dashboardContent.dashboard.findParameterAsync('wkt').then((param) => {
      if (param) {
        console.log('registerMultimapParameterListener: ', param);
        const unregisterFunction = param.addEventListener(tableau.TableauEventType.ParameterChanged, (event) => {
          console.log('multimap parameter changed: ', param, globalView, event);
          tableau.extensions.dashboardContent.dashboard.getParametersAsync().then((params) => {
            // iterate through parameters and set the current value
            params.forEach((param) => {
              if (param.name === 'wkt' && (param.currentValue.value !== savedWktValue)) {
                console.log('multimap(wkt) parameter found: ', param);


                if (param.currentValue.value === 'POLYGON((-180 -90, 180 -90, 180 90, -180 90, -180 -90))') {
                  console.log('RESET encountered -- ignoreResetCount: ', ignoreResetCount);
                  if (ignoreResetCount > 0 ) {
                    console.log('ignoring reset');
                    ignoreResetCount--;
                    return;
                  } else {
                    console.log('reset back to zero');
                    ignoreResetCount = 0;
                  }
                }


                const { latitude, longitude, wkt } = mapLayers[0].kineticaSettings;

                console.log('gpudb: ', gpudb);
                console.log('selectedDatasource: ', selectedDatasource);
                console.log('latitude: ', latitude);
                console.log('longitude: ', longitude);
                console.log('wkt: ', wkt);
                
                if (
                  gpudb &&
                  selectedDatasource &&
                  ((longitude !== '' && latitude !== '') || wkt !== '')
                ) {

                  let multimapGeom = param.currentValue.value;
                  let multimapExpr = '';
                  if (longitude !== '' && latitude !== '') {
                    multimapExpr = `STXY_INTERSECTS(${longitude},${latitude},GEOMETRY('${multimapGeom}')) = 1;`;
                  } else if (wkt !== '') {
                    multimapExpr = `ST_INTERSECTS(${wkt}, GEOMETRY('${multimapGeom}')) = 1;`;
                  }

                  if (filterByViewportMode) {
                    const viewExtent = transformExtent(map.getView().calculateExtent(map.getSize()), 'EPSG:3857', 'EPSG:4326');
                    buildView(viewExtent, filters, multimapExpr);
                  } else {
                    buildView(null, filters, multimapExpr);
                  }
                  //savedWktValue = multimapGeom;
                }
              }
            });
          });
        });
        multimapUnregisterHandlerFunctions.push(unregisterFunction);
      } else {
        console.log('multimap parameter not found... skipping');
      }
    });
  };

  // Load Datasources
  useEffect(() => {
    const { longitude, latitude, wkt } = mapLayers[0].kineticaSettings;
    let dsources = [];
    if (endpoint !== '') {
      console.log('Load Datasources Endpoint: ', endpoint);
      const options = {
        timeout: 60000,
        username: username,
        password: password,
      };
      if (username == '' || password == '') {
        console.log('no username/password detected yet... skipping');
        return;
      }
      const gpudb = new GPUdb(endpoint, options);

      if (selectedDatasource) {
        setGpudb(gpudb);
        setDatasources([selectedDatasource]);
        setSelectedDatasource(selectedDatasource);
        updateFilteringMode(filteringMode);
        return;
      }

      console.log('No selected datasource.  Loading datasources');

      tableau.extensions?.workbook?.getAllDataSourcesAsync()
      .then(datasources => {
        console.log('DATASOURCES: ', datasources);
        const tablePromises = datasources.map(ds => {
          console.log('data: ', ds);
          return ds.getActiveTablesAsync();
        });
        Promise.all(tablePromises).then(tables => {
          console.log('TABLE');
          // console.dir(tables[0][0]);
          if(tables && tables.length > 0 && tables[0].length > 0) {
            const tableSummary = tables[0][0];
            console.log('tableSummary: ', tableSummary);

            let schema = '';
            let table = '';
            if (tableSummary.customSQL === undefined) {
              // expect tableSummary.id to be in the form of '[schema].[table]'
              [schema, table] = tableSummary.id.split('.');

              // remove brackets from schema and table
              schema = schema.substring(1, schema.length - 1);
              table = table.substring(1, table.length - 1);
            }

            if (tableSummary.customSQL && tableSummary.customSQL.length > 0) {
              // extract schema and table from customSQL lowercased
              let customSQL = tableSummary.customSQL;
              saveSqlBase(customSQL);
              let match = customSQL.match(/from\s+([a-zA-Z0-9_\.]+)/i);
              if (match) {
                console.log('match: ', match);
                [schema, table] = match[1].split('.');
              } else {
                console.error('Unable to parse customSQL: ', customSQL)
              }
            }

            console.log('schema: ', schema);
            console.log('table: ', table);
            const dsName = `${schema}.${table}`;
            let columns = [];
            gpudb.show_table(dsName, { get_column_info: 'true' }, (err, data) => {
              if (!err) {
                let foundTrackId = false;
                let foundX = false;
                let foundY = false;
                let foundTimestamp = false;

                console.log('loadDatasources: Found columns: ', data['type_schemas'][0]);
                let record = JSON.parse(data['type_schemas'][0])
                record.fields.forEach(field => {
                  if (field.name === 'TRACKID' && data['properties'][0][field.name].includes('shard_key')) {
                    foundTrackId = true;
                  } else if (field.name === 'x' && data['properties'][0][field.name].includes('data')) {
                    foundX = true;
                  } else if (field.name === 'y' && data['properties'][0][field.name].includes('data')) {
                    foundY = true;
                  } else if (field.name === 'TIMESTAMP' && data['properties'][0][field.name].includes('timestamp')) {
                    foundTimestamp = true;
                  }
                  columns.push({
                    name: field.name,
                    label: field.name.replace(/_/g, ' '),
                    type: field.type,
                    properties: data['properties'][0][field.name] || [],
                  });
                });
                dsources.push({
                  name: dsName,
                  table: {
                    schema: schema,
                    name: table,
                    apiUrl: endpoint,
                    username: username,
                    password: password,
                    columns: columns,
                    hasTracks: foundTrackId && foundX && foundY && foundTimestamp,
                  }
                });
                console.log('loadDatasources: dsources:', dsources);
                setDatasources(dsources);
                setSelectedDatasource(dsources[0]);
                setGpudb(gpudb);
                setError(componentName, '');
                console.log('UPDATE FILTERING MODE FROM LOAD DATASOURCES');
                updateFilteringMode(filteringMode);
                if ((latitude && longitude) || wkt) {
                  console.log('calling initial buildView after loadingDatasources');
                  buildView(null, filters);
                }
              } else {
                const msg = `loadDatasources: No columns found for table: : ${table}`;
                console.error(msg);
                setError(componentName, msg);
                return;
              }
            });
          } else {
            const msg = 'No tables found';
            console.error(msg);
            setError(componentName, msg);
            return;
          }
        });
      });
    }
  },
    [endpoint, username, password, filteringMode]
  );

  const handleDrawMapClick = useCallback(
    event => {
      console.log('IN handleDrawMapClick2: ', event);
      // Remove the last feature drawn since only one features is used for the filter
      if (DrawLayer.getSource().getFeatures().length > 0) {
        DrawLayer.getSource().removeFeature(DrawLayer.getSource().getFeatures()[0]);
      }
      setDrawnFeatures([event]);
      setSetResetCount(event);
    },
    []
  );

  useEffect(() => {
    mapLayers.forEach(layer => {
      ignoreResetCount++;
    });
    if (!filterByViewportMode) {
      ignoreResetCount++;
    }
    console.log('setResetCount - ignoreResetCount: ', ignoreResetCount);

  }, [setResetCount]);

  useEffect(() => {
    const { longitude, latitude, wkt } = mapLayers[0].kineticaSettings;

    if (!tableau.extensions.dashboardContent) {
      return;
    }

    if (!filterByViewportMode) {
      const { worksheets } = tableau.extensions.dashboardContent.dashboard;
      worksheets.forEach(worksheet => {
        try {
          if(latitude && longitude && OUTBOUND_RANGE_FILTER_ENABLED) {
            worksheet.clearFilterAsync(transformString(latitude), { suppressCallback: true});
            worksheet.clearFilterAsync(transformString(longitude), { suppressCallback: true});
          }
        } catch (error) {
          console.error('Error clearing filters: ', error);
        }
      });
      console.log('removing key');

      if(map && viewportKey) {
        unByKey(viewportKey);
        setViewportKey(null);
      }
    } else if(filterByViewportMode && viewportKey == null) {
      console.log('setting key1');

      setViewportKey(map.on('moveend', (evt) => {
        console.log('updateMapFeatures --> moveend1', evt);
        mapMoveHandler(evt);
      }));
    } else if(filterByViewportMode && viewportKey != null) {
      console.log('setting key2');

      unByKey(viewportKey);
      setViewportKey(map.on('moveend', (evt) => {
        console.log('updateMapFeatures --> moveend12', evt);
        mapMoveHandler(evt);
      }));
    }
  }, [filterByViewportMode, selectedDatasource, map])


  function transformString(str) {
    // Split the string on the underscore character
    var tokens = str.split('_');

    // Loop through the tokens and transform each one
    var transformedTokens = tokens.map(function(token) {
      // Capitalize the first letter of the token
      var capitalizedToken = token.charAt(0).toUpperCase() + token.slice(1);

      return capitalizedToken;
    });

    // Join the transformed tokens with a space character
    var transformedString = transformedTokens.join(' ');

    return transformedString;
  }

  const createLayerFiltersStmt = (expression, newViewName) => {
    let _expr = expression;
    if (_expr.endsWith(';')) {
      _expr = _expr.substring(0, _expr.length - 1);
    }
    let stmt = sqlBase;
    if (_expr.length > 0) {
      stmt += ' WHERE ';
    }
    if (_expr.length > 0) {
      stmt += _expr;
    }

    stmt = `create temp materialized view ${newViewName} as (${stmt}) using table properties (ttl=20)`;
    console.log('createLayerFiltersStmt: ', stmt);
    return stmt;
  };


  const buildView = async(viewExtent, inboundFilters, multimapExpr) => {
    console.log('>>>buildView');
    console.dir(viewExtent, inboundFilters);

    const { latitude, longitude, wkt } = mapLayers[0].kineticaSettings;

    let viewName = undefined;
    let outboundFilterCoords = [];
    let wktGeoms = [];

    if (
      !(gpudb &&
      selectedDatasource &&
      ((longitude !== '' && latitude !== '') || wkt !== ''))
    ) {
      console.log('<<<buildView (incomplete data)');
      if (gpudb && selectedDatasource) {
        console.log('buildView opening up map settings');
        setSelectedLayer({'op': 'edit', 'index': -1});
      }
      return;
    }

    console.log('inboundFilters: ', inboundFilters);
    const validFilters = await Promise.all(inboundFilters.map(async (curFilter) => {
      const columns = selectedDatasource.table.columns;
      let cur = curFilter;
      if (cur.additionalInfo == null) {
        // Missing additional info
        const { nameNoParen, additionalInfo } = convertTableColumnName(cur.column);
        cur.nameNoParen = nameNoParen;
        cur.additionalInfo = additionalInfo;
      }
    
      const dateTimeFunctions = ['YEAR', 'QUARTER', 'MONTH', 'DAY', 'HOUR', 'MINUTE', 'SECOND'];
      let validFilter = null;
      let curFound = false;
    
      for (let i = 0; i < columns.length; i++) {
        const column = columns[i];

        const { nameNoParen, functionName, isBinned } = cur;
        if (functionName && TABLEAU_AGGREGATIONS_LIST.includes(functionName)) {
          continue;
        }

        // Note removed nameNoParen matching with label.  This breaks caclulated fields if the calculated field name matches the column name
        // if (column.label.toLowerCase() === nameNoParen || column.name.toLowerCase() === nameNoParen) {
        if (column.name.toLowerCase() === nameNoParen) {
          curFound = true;
          if (!validFilter) {
            validFilter = {
              [column.name]: {
                include: cur.include,
                exclude: cur.exclude,
                isExcludeMode: cur.isExcludeMode,
                includeNullValues: cur.includeNullValues,
                minValue: cur.minValue,
                maxValue: cur.maxValue,
                type: cur.dataType || cur.type,
                exteriorExpression: functionName,
              },
            };
          }

          if (dateTimeFunctions.includes(functionName)) {
            validFilter[column.name][functionName] = cur.include;
          }

          if (isBinned) {
            const binRangesRet =  await getColumnBinRange(column.name);
            console.log('binRangesRet: ', binRangesRet); 
            const _binRanges = binRangesRet 
                ? binRangesRet
                : (binRanges && binRanges[`${column.name} (bin)`] ? binRanges: null);

            if (_binRanges && _binRanges[`${column.name} (bin)`]) {
              validFilter[column.name]['binned'] = _binRanges[`${column.name} (bin)`];

            } else {
              setError('Warning', `Unable to find bin ranges for ${column.name} (bin).  Please add a discrete filter.`);
            }
          }
        }
      }

      if (!curFound) {
        // check to see if this is a calculated column
        const datasources = await tableau.extensions?.workbook?.getAllDataSourcesAsync();
        datasources.forEach(datasource => {
          const calcFields = datasource.fields?.filter(field => (field.isCalculatedField === true)).map(field => field.name);
          let nameNoParen = cur.column;
          if (nameNoParen.match(/^Action+\s*\(/)) {
            nameNoParen = nameNoParen.substring(nameNoParen.indexOf('(') + 1, nameNoParen.lastIndexOf(')'));
            const ignoreAction = cur.column.substring(0, cur.column.indexOf('('));
            console.log('Removing Action keyword from column name for calculated field ', ignoreAction);
          }
          console.log('calcFields: ', calcFields, ', looking for: ', nameNoParen);
          if (calcFields && calcFields.includes(nameNoParen)) {
            console.log('found calc field: ', cur, nameNoParen, calculatedFields);
            if (calculatedFields?.[nameNoParen]) {
              console.log('found calc field in calculatedFields: ', calculatedFields[nameNoParen]);
              validFilter = {
                [cur.column]: {
                  include: cur.include,
                  exclude: cur.exclude,
                  isExcludeMode: cur.isExcludeMode,
                  includeNullValues: cur.includeNullValues,
                  minValue: cur.minValue,
                  maxValue: cur.maxValue,  
                  type: cur.dataType,
                  isCalculatedField: true,
                  sql: calculatedFields[nameNoParen].sql,
                },
              };
            } else {
              console.log('not found calc field: ', cur, nameNoParen);
              const msg = `You're using a calculated field as a filter that this extension doesn't know about.  Click HERE to link your .twb file necessary for this filter.</p>`;
              setError('Warning', `${msg}`, () => {
                console.log('pop up the file uploader');
                setErrorMsg({});
                inputFile.current.click();
              });
              setTwbFile(null);
            }
          }
        });
      }

      return validFilter;
    }));

    // convert validFilters to an object buildExpression can use
    // TODO: Remove the validFiltersObj, it is not being used after March 5, 2025
    const validFiltersObj = validFilters.reduce((acc, cur) => {
      if (cur !== null) {
        const [key, value] = Object.entries(cur)[0];
        acc[key] = value;
      }
      return acc;
    }, {});

    // determine filter expression
    let filterExpression = '';
    if (validFilters?.length > 0) {
      console.info('validFiltersObj', validFiltersObj, validFilters);
      filterExpression = buildExpression(validFilters);
      console.info('FILTER EXPRESSION', filterExpression);
    }

    console.log('filterExpression: ', filterExpression);
    if (layersFilterText.length > 0) {
      if (filterExpression.length > 0) {
        filterExpression = `${filterExpression} AND ${layersFilterText}`;
      } else {
        filterExpression = layersFilterText;
      }
      console.log('filterExpression including layersFilterText: ', filterExpression);
    }

    // determine stxy_intesect expression based on drawn geometry
    let drawnGeometryExpression = '';
    if (multimapExpr) {
      drawnGeometryExpression = multimapExpr;
    } else {
      if (drawLayer.getSource().getFeatures().length > 0) {
        const extent = map.getView().calculateExtent(map.getSize());
        const features = drawLayer.getSource().getFeaturesInExtent(extent);
  
        for (let i=0; i < features.length; i++) {
          const feature = features[i];
          const [minx, miny, maxx, maxy] = feature.getGeometry().getExtent();
          console.log('GEOMETRY: ', feature.getGeometry(), minx, miny, maxx, maxy);
          const wktFormatter = new WKT();
          const cloneGeom = feature.getGeometry().clone().transform('EPSG:3857', 'EPSG:4326');
          const wktgeom = wktFormatter.writeGeometry(cloneGeom);
          console.log('wkt: ' + wktgeom);
          wktGeoms.push(wktgeom);

          const min = transform([minx, miny], 'EPSG:3857', 'EPSG:4326');
          const max = transform([maxx, maxy], 'EPSG:3857', 'EPSG:4326');

          if (outboundFilterCoords.find(coord => 
                coord[0] === min && 
                coord[1] === max)) {
            continue;
          } else {
            outboundFilterCoords.push([min, max]);
          }

          if (longitude !== '' && latitude !== '') {
            drawnGeometryExpression = `STXY_INTERSECTS(${longitude},${latitude},GEOMETRY('${wktgeom}')) = 1;`;

            console.log('drawnGeometryExpression: ' + drawnGeometryExpression);
          } else if (wkt !== '') {
            drawnGeometryExpression = `ST_INTERSECTS(${wkt}, GEOMETRY('${wktgeom}')) = 1;`;

            console.log('drawnGeometryExpression: ' + drawnGeometryExpression);
          }
        }
      }
    }

    console.log('drawnGeometryExpression: ', drawnGeometryExpression);

    // if filter by viewport is enabled, then ensure that all filters 
    // intersect with the current viewport
    let viewExtentWktExpression = '';
    if (viewExtent && filterByViewportMode) {
      const [minx, miny, maxx, maxy] = viewExtent;
      const format = new WKT();

      var wktgeom = format.writeGeometry(fromExtent(viewExtent));
      if (longitude !== '' && latitude !== '') {
        viewExtentWktExpression = `STXY_INTERSECTS(${longitude},${latitude},GEOMETRY('${wktgeom}')) = 1;`;

        console.log('viewExtentWktExpression: ' + viewExtentWktExpression);
      } else if (wkt !== '') {
        viewExtentWktExpression = `ST_INTERSECTS(${wkt}, GEOMETRY('${wktgeom}')) = 1;`;

        console.log('viewExtentWktExpression: ' + viewExtentWktExpression);
      }
    }

    // combine filter, drawn geometry, and viewport expressions
    let expression = '';
    let expressionWithoutViewExtent = '';
    // if (filterExpression !== '') {
    //   expression = filterExpression;
    //   expressionWithoutViewExtent = filterExpression;
    // }

    // if (drawnGeometryExpression !== '') {
    //   expression = expression === '' ? drawnGeometryExpression : `${expression} AND ${drawnGeometryExpression}`;
    //   expressionWithoutViewExtent = expressionWithoutViewExtent === '' ? drawnGeometryExpression : `${expressionWithoutViewExtent} AND ${drawnGeometryExpression}`;
    // }

    // if (viewExtentWktExpression !== '') {
    //   expression = expression === '' ? viewExtentWktExpression : `${expression} AND ${viewExtentWktExpression}`;;
    // }

    if (filterExpression !== '' && drawnGeometryExpression !== '' && viewExtentWktExpression !== '') {
      expression = `${filterExpression} AND ${drawnGeometryExpression} AND ${viewExtentWktExpression}`;
      expressionWithoutViewExtent = `${filterExpression} AND ${drawnGeometryExpression}`;
    }
    else if (filterExpression !== '' && drawnGeometryExpression !== '') {
      expression = `${filterExpression} AND ${drawnGeometryExpression}`;
      expressionWithoutViewExtent = `${filterExpression} AND ${drawnGeometryExpression}`;
    }
    else if (filterExpression !== '' && viewExtentWktExpression !== '') {
      expression = `${filterExpression} AND ${viewExtentWktExpression}`;
      expressionWithoutViewExtent = `${filterExpression}`;
    }
    else if (drawnGeometryExpression !== '' && viewExtentWktExpression !== '') {
      expression = `${drawnGeometryExpression} AND ${viewExtentWktExpression}`;
      expressionWithoutViewExtent = `${drawnGeometryExpression}`;
    }
    else if (filterExpression !== '') {
      expression = filterExpression;
      expressionWithoutViewExtent = filterExpression;
    }
    else if (drawnGeometryExpression !== '') {
      expression = drawnGeometryExpression;
      expressionWithoutViewExtent = drawnGeometryExpression;
    }
    else if (viewExtentWktExpression !== '') {
      expression = viewExtentWktExpression;
    }

    console.log('expression: ', expression)
    console.log('expressionWithoutViewExtent: ', expressionWithoutViewExtent);


    // set up geoms for outbound filter
    let wktValue;
    if (outboundFilterCoords.length === 0) {
      // When no wkt filter is applied revert back to the entire world
      wktValue = 'POLYGON((-180 -90, 180 -90, 180 90, -180 90, -180 -90))';
    } else {
      // apply geographic filter outbound to worksheets
      wktValue = wktGeoms[wktGeoms.length - 1];
    }

    let wktviewportValue;
    if (viewExtent) {
      wktviewportValue = wktgeom;
    } else {
      wktviewportValue = 'POLYGON((-180 -90, 180 -90, 180 90, -180 90, -180 -90))';
    }

    // call gpudb filter
    setError(componentName, '');
    
    const { table } = selectedDatasource;
    const baseTable = `${table.schema}.${table.name}`;
    
    if (expressionWithoutViewExtent !== '') {
      // create a view for each layer
      const layerViewMap = {};
      const executeSqlPromises = [];
      
      for (let i = 0; i < mapLayers.length; i++) {
        const layer = mapLayers[i];
        const randomNumber = Math.floor(Math.random() * 1000000000) + 1;
        const viewName = `${baseTable}_view_${layer.id}_${randomNumber}`;
      
        const offset = 0;
        const limit = -9999;
        const options = {};
        if (layer.layerType === LAYER_TYPES.K_WMS_BASE_LAYER.value) {
          gpudb.execute_sql(
            createLayerFiltersStmt(expressionWithoutViewExtent, viewName),
            offset,
            limit,
            null,
            [],
            options,
            (err, data) => {
              if (data) {
                console.info('DATA2: ', data);
                if (layer.id === '0000') {
                  globalView = viewName;
                  if (viewExtent) {
                    updateMapFeatures(viewExtent, viewName, wktValue);
                  } else {
                    setCount(data.count_affected);

                    updateParameters({ 'wkt': wktValue });
                    savedWktValue = wktValue;
                  }
                }
                updateLayerView(layer, viewName);
                layerViewMap[layer.id] = viewName;
              } else {
                console.log('setting updateMapFeatures view', baseTable);
                if (layer.id === '0000') {
                  globalView = baseTable;
                  if (viewExtent) {
                    updateMapFeatures(viewExtent, baseTable, wktValue);
                  } else {
                    updateCount(baseTable);

                    updateParameters({ 'wkt': wktValue });
                    savedWktValue = wktValue;
                  }
                }
              }
            }
          );
        }
      }

    } else {
      console.log('buildView: no expressionWithoutViewExtent... calling updateMapFeatures with baseTable: ', baseTable);
      globalView = baseTable;
      const newMapLayers = mapLayers.map(lyr => {
        return {
          ...lyr,
          kineticaSettings: {
            ...lyr.kineticaSettings,
            view: lyr.baseTable
          },
        }
      });
      setMapLayers(newMapLayers);
      if (viewExtent) {
        updateMapFeatures(viewExtent, baseTable, wktValue);
      } else {
        updateCount(baseTable);

        updateParameters({'wkt': wktValue});
        savedWktValue = wktValue;
      }
    }
  }

  // const updateSheetsByLayerFilters = debounce(async() => {
  //   // if we need to update sheet filters, do it here
  // }, 1000);

  const updateMapFeatures = async(extent, viewName, wktValue) => {
    const { latitude, longitude, wkt, view } = mapLayers[0].kineticaSettings;

    let viewExtent = extent;
    if (!extent) {
      viewExtent = transformExtent(map.getView().calculateExtent(map.getSize()), 'EPSG:3857', 'EPSG:4326');
    }
    console.log('the view for updateMapFeatures is view', view);
    console.log('the name for updateMapFeatures is view', viewName);
    let _view = viewName ? viewName : view;

    console.log("datasources: ", datasources);
    console.log("selectedDatasource: ", selectedDatasource);

    const { table } = selectedDatasource;
    const format = new WKT();
    let wktgeom = format.writeGeometry(fromExtent(viewExtent));
    let viewExtentWktExpression = '';
    if (longitude !== '' && latitude !== '') {
      viewExtentWktExpression = `STXY_INTERSECTS(${longitude},${latitude},GEOMETRY('${wktgeom}')) = 1;`;
    } else if (wkt !== '') {
      viewExtentWktExpression = `ST_INTERSECTS(${wkt}, GEOMETRY('${wktgeom}')) = 1;`;
    }

    console.log('updateMapFeatures: ', _view);
    if (_view && _view !== '') {
      gpudb.filter(
        _view,
        '',
        viewExtentWktExpression,
        { ttl: '20' },
        (err, data) => {
          if (data) {
            console.info('DATA: ', data);
            setCount(data.count);
            updateParameters({
              'wktviewport': wktgeom
            });

            if (wktValue) {
              console.log('wktValue: ', wktValue)
              
              if (wktValue !== savedWktValue) {
                updateParameters({
                  'wkt': wktValue,
                });
                savedWktValue = wktValue;
              }

            }
          }
        }
      );
    } else {
      console.warn('No view selected');
    }
  };

  const loadTableInfo = async (gpudb, schemaName, tableName) => {
    let tableInfo = [];
    const resp = await gpudb.show_table(schemaName, {});
    const { additional_info, properties, type_labels, table_names, type_schemas } = resp;
    if (table_names && additional_info) {
      table_names.forEach((name, idx) => {
        try {
          const { table_ttl, collection_names } = additional_info[idx];
          if (table_ttl < 0) {
          // only include tables that are permanent

            let foundTrackId = false;
            let foundX = false;
            let foundY = false;
            let foundTimestamp = false;
            let columns = [];

            let record = JSON.parse(type_schemas[idx])
            record.fields.forEach(field => {
              const { name: fieldName, type: fieldType } = field;
              if (fieldName === 'TRACKID' && properties[idx][fieldName].includes('shard_key')) {
                foundTrackId = true;
              } else if (fieldName === 'x' && properties[idx][fieldName].includes('data')) {
                foundX = true;
              } else if (fieldName === 'y' && properties[idx][fieldName].includes('data')) {
                foundY = true;
              } else if (fieldName === 'TIMESTAMP' && properties[0][fieldName].includes('timestamp')) {
                foundTimestamp = true;
              }
              columns.push({
                name: fieldName,
                label: fieldName.replace(/_/g, ' '),
                type: fieldType,
                properties: properties[idx][fieldName] || [],
              });
            });

            tableInfo.push({
              full_name: `${collection_names}.${name}`,
              name,
              table_ttl,
              collection_names,
              schema: collection_names,
              apiUrl: endpoint,
              username: username,
              password: password,
              columns: columns,
              hasTracks: foundTrackId && foundX && foundY && foundTimestamp,
            });
          }
        } catch (ex) {
          console.log('Error while reading table from schema', ex);
          console.log(name, type_schemas[idx], properties[idx], additional_info[idx]);
        }
      });
      setKTableList((prev) => {
        function compareFn(a, b) {
          if (a.full_name < b.full_name) {
            return -1;
          } else if (a.full_name > b.full_name) {
            return 1;
          }
          return 0;
        }
        return prev ? prev.concat(tableInfo).sort(compareFn) : tableInfo.sort(compareFn);
      });

    } else {
      console.log('Missing table names from show table call');
    }
  };

  useEffect(() => {
    // Load Kinetica Tables and calculated Fields
    if (gpudb != null && !hasLoadedKineticaTables) {
      console.log('Loading Kinetica tables from schemas');
      setHasLoadedKineticaTables(true);
      const kSchemas = schemaListAsString.trim().split(',');
      kSchemas.forEach((sch) => {
        if (sch.trim() !== '') {
          loadTableInfo(gpudb, sch.trim());
        }
      });
    }

    if (gpudb != null && storeCalculatedFieldInKinetica === CALC_FIELD_STORAGE_TYPES.KINETICA_TABLE_STORAGE.value && hasLoadedCalFields == false) {
      loadCalcField();
    }
  }, [gpudb, storeCalculatedFieldInKinetica, hasLoadedCalFields, calcFieldsSaveName, hasLoadedKineticaTables]);

  const loadCalcField = async () => {
    setHasLoadedCalFields(true);
    const calcFields = await loadKineticaCalculatedField(gpudb, username, calcFieldsSaveName, KINETICA_CALC_FIELD_TABLE);
    console.log('loaded calculated fields from Kinetica', calcFields);
    setCalculatedFields(JSON.parse(calcFields));
  };

  // Calls buildView
  useEffect(() => {
    const { latitude, longitude, wkt } = mapLayers[0].kineticaSettings;

    if (
      gpudb &&
      selectedDatasource &&
      ((longitude !== '' && latitude !== '') || wkt !== '')
    ) {

      if (filterByViewportMode) {
        const viewExtent = transformExtent(map.getView().calculateExtent(map.getSize()), 'EPSG:3857', 'EPSG:4326');
        buildView(viewExtent, filters);
      } else {
        buildView(null, filters);
      }
    }
  }, [
    gpudb,
    selectedDatasource,
    filterByViewportMode,
    map,
    filters,
    drawnFeatures,
    layersFilterText,
  ]);

  const loadFilters = async (worksheets, currentFilteringMode) => {
    console.log('LOADING FILTERS REGISTER: loadFilters', worksheets.length, currentFilteringMode);
    Promise.all(
      worksheets.map(async (worksheet, wsIndex2) => {
        if (currentFilteringMode === FILTERING_MODE_FILTER && wsIndex2 === 1) {
          const dashboardFilters = [];
          const originalFilters = [];
          // Note using reduce because reduce works with async and await but forEach does not
          const filters2 = await worksheets.reduce(async (acc, worksheet, currentIndex) => {
            if (currentIndex < 3) {
              // Collect only the first 3 sheet filters as it should be enough to know what filters have been added to the dashboard
              const worksheetFilters = await worksheet.getFiltersAsync();
              const enhancedFilters = worksheetFilters.map((fil) => {
                const { nameNoParen, additionalInfo } = convertTableColumnName(fil.fieldName);
                fil.nameNoParen = nameNoParen;
                fil.additionalInfo = additionalInfo;
                return fil;
              });
              originalFilters.push(...enhancedFilters);
              const handledFilters = handleFilters(enhancedFilters);
              console.info('LOADING FILTERS REGISTER', worksheet.name, FILTERING_MODE_FILTER, handledFilters);
              dashboardFilters.push(...handledFilters);
            }
            return await acc;
          }, []);


          console.log("LOADING FILTERS REGISTER consolidated filters and orginal filters", dashboardFilters, originalFilters);

          const foundFilters = [];
          // consolidate duplicate filters and track how many times they were seen in the worksheets
          dashboardFilters.forEach((f1, f1Index) => {
            const dupsIndex = foundFilters.findIndex(({ count, filter: f2 }) => {
              return (
                f1.nameNoParen === f2.nameNoParen
                && f1.column === f2.column
                && f1.includeNullValues === f2.includeNullValues
                && f1.include?.toString() === f2.include?.toString()
                && (f1.minValue === f2.minValue || (f1.minValue?.toString() === f2.minValue?.toString())) //toString is for comparing NaN to NaN
                && (f1.maxValue === f2.maxValue || (f1.maxValue?.toString() === f2.maxValue?.toString()))
                && f1.type === f2.type
              );
            });
            if (dupsIndex > -1) {
              foundFilters[dupsIndex].count++;
            } else {
              foundFilters.push({ count: 1, filter: f1 });
            }
          });
          console.log("LOADING FILTERS REGISTER foundFilters", foundFilters);

          const newFilters = foundFilters.filter((fil) => {
            const { filter: aFilter } = fil;
            if (isNaN(aFilter.minValue) && isNaN(aFilter.maxValue) && aFilter.isAllSelected == null && aFilter.isExcludeMode == null && aFilter.includeNullValues) {
              // Not a valid filter as it includes everything
              return false;
            }

            if (fil.count === 1 && aFilter.column.indexOf('Action') > -1) {
              // Action filters are ones added by actions on other worksheets?
              return true;
            }

            return fil.count > 1;
          }).map((fil) => { return fil.filter });
          console.log("LOADING FILTERS REGISTER finalFilters", newFilters);
          setFilters(newFilters);

        } else if (currentFilteringMode === FILTERING_MODE_SELECTION) {
          // See if we can get all filters
          const filters = await worksheets.reduce(async (acc, worksheet) => {
            const marks = await worksheet.getSelectedMarksAsync();
            if (marks.data.length > 0) {
              const { data, columns } = marks.data[marks.data.length - 1];
              if (columns.length > 0) {
                const regex = /([a-zA-Z_\ \(\d\)]+)/gi;
                const filters = columns
                  .map((column, idx) => {
                    const values = data.reduce((acc, cur) => {
                      if (cur.length > 0) {
                        acc.push(cur[idx].value);
                      }
                      return acc;
                    }, []);
                    return {
                      column: column.fieldName,
                      dataType: column.dataType,
                      include: values,
                    };
                  })
                  .filter(item => {
                    return !item.column.match(regex);
                  });
                const arr = await acc;
                return arr.concat(filters);
              }
            }
            return await acc;
          }, []);
          console.info(FILTERING_MODE_SELECTION, filters);
        }
      })
    );
  };

  const loadConfig = async () => {
    if (updateParameterDelay.current === undefined) {
      updateParameterDelay.current = 1000;
    }
    const { longitude, latitude, wkt } = mapLayers[0].kineticaSettings;

    if (reloadConfigRef.current && tableau.extensions.settings) {
      reloadConfigRef.current = false;
      const { worksheets } = tableau.extensions.dashboardContent.dashboard;

      // Check for saved filtering mode
      const currentFilteringMode =
        tableau.extensions.settings.get('filteringMode');
      if (currentFilteringMode != null) {
        updateFilteringMode(currentFilteringMode);
        setFilteringMode(currentFilteringMode);
      }
      console.log('loadConfig: currentFilteringMode: ', currentFilteringMode);
      await loadFilters(worksheets, currentFilteringMode);

  // Promise.all(
  //   worksheets.map(async worksheet => {
  //     if (currentFilteringMode === FILTERING_MODE_FILTER) {
  //       worksheet.getFiltersAsync().then(data => {
  //         const filters = handleFilters(data);
  //         console.info(FILTERING_MODE_FILTER, filters);
  //         setFilters(filters);
  //       });
  //     } else if (currentFilteringMode === FILTERING_MODE_SELECTION) {
  //       // See if we can get all filters
  //       const filters = await worksheets.reduce(async (acc, worksheet) => {
  //         const marks = await worksheet.getSelectedMarksAsync();
  //         if (marks.data.length > 0) {
  //           const { data, columns } = marks.data[marks.data.length - 1];
  //           if (columns.length > 0) {
  //             const regex = /([a-zA-Z_\ \(\d\)]+)/gi;
  //             const filters = columns
  //               .map((column, idx) => {
  //                 const values = data.reduce((acc, cur) => {
  //                   if (cur.length > 0) {
  //                     acc.push(cur[idx].value);
  //                   }
  //                   return acc;
  //                 }, []);
  //                 return {
  //                   column: column.fieldName,
  //                   dataType: column.dataType,
  //                   include: values,
  //                 };
  //               })
  //               .filter(item => {
  //                 return !item.column.match(regex);
  //               });
  //             const arr = await acc;
  //             return arr.concat(filters);
  //           }
  //         }
  //         return await acc;
  //       }, []);
  //       console.info(FILTERING_MODE_SELECTION, filters);
  //     }
  //   })
  // );

      // Check for saved datasource
      let selectedDatasource = tableau.extensions.settings.get('datasource');
      console.log('loadConfig: selectedDatasource: ', selectedDatasource);
      if (selectedDatasource != null) {
        selectedDatasource = JSON.parse(selectedDatasource);
        setSelectedDatasource(selectedDatasource);
        setDatasources([selectedDatasource]);
        setError(componentName, '')
      } 
      
      // Check for saved endpoint
      let endpoint = tableau.extensions.settings.get('endpoint');
      console.log('loadConfig: endpoint: ', endpoint);
      if (endpoint != null) {
        setEndpoint(endpoint);
      }

      // Check for saved username
      let username = tableau.extensions.settings.get('username');
      console.log('loadConfig: username: ', username);
      if (username != null) {
        setUsername(username);
      }

      // Check for saved password
      let password = tableau.extensions.settings.get('password');
      console.log('loadConfig: password is not null: ', password != null);
      if (password != null) {
        setPassword(password);
      }

      // Check for saved mapLayers
      let mapLayers = tableau.extensions.settings.get('mapLayers');
      console.log('loadConfig: mapLayers: ', mapLayers);
      if (mapLayers != null) {
        // update any previously saved twb file that contained the
        // old wms classbreak delimiter ',' and change it to use '|'.
        let mapLayersParsed = JSON.parse(mapLayers);
        let updatedAllStyleRanges;
        let updatedAllStyleColors;
        let updatedAllStyleShapes;
        let updatedAllStyleSizes;
        mapLayersParsed = mapLayersParsed.map(lyr => {
          let cbStyleOpts = lyr.kineticaSettings?.cbStyleOptions;
          if (cbStyleOpts?.allStyleRanges?.split('|').length === 1 
              && cbStyleOpts?.allStyleRanges?.includes(',')) {
            updatedAllStyleRanges = cbStyleOpts.allStyleRanges.split(',').join('|');
          } else {
            updatedAllStyleRanges = cbStyleOpts.allStyleRanges;
          }
          if (cbStyleOpts?.allStyleColors?.split('|').length === 1
              && cbStyleOpts?.allStyleColors?.includes(',')) {
            updatedAllStyleColors = cbStyleOpts.allStyleColors.split(',').join('|');
          } else {
            updatedAllStyleColors = cbStyleOpts.allStyleColors;
          }
          if (cbStyleOpts?.allStyleShapes?.split('|').length === 1
              && cbStyleOpts?.allStyleShapes?.includes(',')) {
            updatedAllStyleShapes = cbStyleOpts.allStyleShapes.split(',').join('|');
          } else {
            updatedAllStyleShapes = cbStyleOpts.allStyleShapes;
          }
          if (cbStyleOpts?.allStyleSizes?.split('|').length === 1
              && cbStyleOpts?.allStyleSizes?.includes(',')) {
            updatedAllStyleSizes = cbStyleOpts.allStyleSizes.split(',').join('|');
          } else {
            updatedAllStyleSizes = cbStyleOpts.allStyleSizes;
          }
          return {
            ...lyr,
            layerType: lyr.layerType == null ? LAYER_TYPES.K_WMS_BASE_LAYER.value : lyr.layerType,
            kineticaSettings: {
              ...lyr.kineticaSettings,
              view: null,
              cbStyleOptions: {
                ...cbStyleOpts,
                allStyleRanges: updatedAllStyleRanges,
                allStyleColors: updatedAllStyleColors,
                allStyleShapes: updatedAllStyleShapes,
                allStyleSizes: updatedAllStyleSizes,
              },
            },
          }
        });
        setMapLayers(mapLayersParsed);
      }

      // Check for saved basemap url
      let basemapUrl = tableau.extensions.settings.get('basemapUrl');
      console.log('loadConfig: basemapUrl: ', basemapUrl);
      if (basemapUrl != null) {
        setBasemapUrl(basemapUrl);
      }

      // Check for saved basemap url
      let schemaListAsStringConfig = tableau.extensions.settings.get('schemaListAsString');
      console.log('loadConfig: schemaListAsString: ', schemaListAsStringConfig);
      if (schemaListAsStringConfig != null) {
        setSchemaListAsString(schemaListAsStringConfig);
      }

      let storeCalculatedFieldInKineticaConfig = tableau.extensions.settings.get('storeCalculatedFieldInKinetica');
      console.log('loadConfig: storeCalculatedFieldInKinetica: ', storeCalculatedFieldInKineticaConfig);
      if (storeCalculatedFieldInKineticaConfig != null) {
        setStoreCalculatedFieldInKinetica(storeCalculatedFieldInKineticaConfig);
      }

      let calcFieldsSaveNameConfig = tableau.extensions.settings.get('calcFieldsSaveName');
      console.log('loadConfig: calcFieldsSaveName: ', calcFieldsSaveNameConfig);
      if (calcFieldsSaveNameConfig != null) {
        setCalcFieldsSaveName(calcFieldsSaveNameConfig);
      }

      // Check for mapboxApiKey
      let mapboxApiKey = tableau.extensions.settings.get('mapboxApiKey');
      console.log('loadConfig: mapboxApiKey: ', mapboxApiKey);
      if (mapboxApiKey && mapboxApiKey.trim() !== '') {
        console.log('using user-defined mapboxApiKey: ', mapboxApiKey);
        setMapboxApiKey(mapboxApiKey);
      }

      // Check for saved center
      let center = tableau.extensions.settings.get('center');
      console.log('loadConfig: center: ', center);
      if (center != null) {
        center = JSON.parse(center);
        setCenter(center);
      }

      // Check for saved zoom
      let zoom = tableau.extensions.settings.get('zoom');
      console.log('loadConfig: zoom: ', zoom);
      if (zoom != null) {
        setZoom(zoom);
      }

      // Check filterByViewportMode
      let filterByViewportMode = tableau.extensions.settings.get('filterByViewportMode');
      console.log('loadConfig: filterByViewportMode: ', filterByViewportMode);
      if (filterByViewportMode != null) {
        setFilterByViewportMode(filterByViewportMode);
      }


      // Check for saved updateParameterDelay
      let _updateParameterDelay = tableau.extensions.settings.get('updateParameterDelay');
      console.log('loadConfig: updateParameterDelay: ', _updateParameterDelay);
      updateParameterDelay.current = _updateParameterDelay;

      // instantiate the gpudb
      if (!gpudb && endpoint && username && password) {
        setGpudb(new GPUdb(endpoint, {
          username: username,
          password: password,
          timeout: 60000
        }));
      } else {
        let msg = 'Please connect to your database using the "Configure" menu.';
        setError(componentName, msg);
      }

      // update the center and zoom values if they are set
      if (center && map && map.getView()) {
        map.getView().setCenter(center);
      }
      if (zoom && map && map.getView()) {
        map.getView().setZoom(zoom);
      }

      // add a map move handler to save the center and zoom values periodically
      if (map) {
        map.on('moveend', (evt) => {
          const view = evt.map.getView();
          const center = view.getCenter();
          const zoom = view.getZoom();
          setCenter(center);
          setZoom(zoom);
          asyncSaveViewport(center, zoom);
        });
      }

      // check if there are filters saved on the worksheets
      if (tableau.extensions.dashboardContent.dashboard && selectedDatasource) {
        const { worksheets } = tableau.extensions.dashboardContent.dashboard;
        worksheets.forEach(worksheet => {
          worksheet.getFiltersAsync().then(filters => {
            let translatedFilters = [];
            console.log('loadConfig: filters: ', filters);
            const f = handleFilters(filters);
            
            // parse the filters and add them to the filters array
            for (let i = 0; i < f.length; i++) {
              let filt = f[i];
              console.log('loadConfig: filt: ', filt);
              if (!filt.column) {
                continue;
              }
              
              // extract the name within the parenthesis
              const name = filt.column.match(/\(([^)]+)\)/)?.[1];
              console.log('loadConfig: name: ', name);

              if (!name) {
                console.log('loadConfig: name not found');
                continue;
              }

              /////////////////////////////////
              // the name could still have parenthesis in it, so we need to remove them
              // extract the name within the parenthesis
              let name2 = name.match(/\(([^)]+)\)/);
              if (name2) {
                name2 = name2[1];
              }
              console.log('loadConfig: name2: ', name2);
              console.log('name || name2: ', name || name2);

              // Note: Testing this with date_dropoff from demo.nyctaxi..
              //  Tableau does not save this filter, so I cannot test properly
              //  during startup / reload. 
              /////////////////////////////////

              filt.column = name  // if the above block works, then add ' || name2' to the value.

              // get the column's datatype from selectedDatasource
              const col = selectedDatasource.table.columns.find(c => c.name === name);
              if (!col) {
                console.log('loadConfig: column not found');
                continue;
              }

              filt.dataType = col.type;
              translatedFilters.push(filt);
            }

            console.log('loadConfig: translatedFilters: ', translatedFilters);
            setFilters(translatedFilters);
          });
        });
      }

      setIsConfigLoaded(true);
    }
  };

  // When basemapUrl changes, update base layer
  useEffect(() => {
    const basemapLayer = map.getLayers().getArray()[0];

    if (basemapUrl === 'OSM') {
      const source = new OlSourceOSM({
        crossOrigin: 'anonymous',
        wrapX: true,
        noWrap: false,
      });
      basemapLayer.setSource(source);
    } else if (basemapUrl === 'satellite-streets-v11' || 
               basemapUrl === 'dark-v11') {
      const mapboxStyleName = basemapUrl;

      const source = new OlSourceXYZ({
        url: `https://api.mapbox.com/styles/v1/mapbox/${mapboxStyleName}/tiles/256/{z}/{x}/{y}?access_token=${mapboxApiKey}`,
        crossOrigin: 'anonymous',
        wrapX: true,
        noWrap: false,
      });
      basemapLayer.setSource(source);
    } else if (basemapUrl !== '') {
      const source = new OlSourceXYZ({
        url: basemapUrl,
        crossOrigin: 'anonymous',
        wrapX: true,
        noWrap: false,
      });
      basemapLayer.setSource(source);
    } else {
      const mapboxStyleName = 'dark-v11';
      const source = new OlSourceXYZ({
        url: `https://api.mapbox.com/styles/v1/mapbox/${mapboxStyleName}/tiles/256/{z}/{x}/{y}?access_token=${mapboxApiKey}`,
        crossOrigin: 'anonymous',
        wrapX: true,
        noWrap: false,
      });
      basemapLayer.setSource(source);      
    }

    // if( selectedDatasource && selectedDatasource.table ) {
    //   setWmsLayer(createWmsLayer(globalView));
    // }
  }, [basemapUrl, map]);

  const updateFilteringMode = useCallback(filteringMode => {
    const { worksheets } = tableau.extensions.dashboardContent.dashboard;

    unregisterHandlerFunctions.forEach(unregisterHandlerFunction => {
      console.log('UNREGISTERING EVENT LISTENERS');
      unregisterHandlerFunction();
    });
    unregisterHandlerFunctions = [];

    // Add filter listeners
    worksheets.forEach((worksheet, wIndex) => {
      console.log('LOADING FILTERS REGISTER, ADDING EVENT LISTENERS', worksheet.name, wIndex);
      console.log('LOADING FILTERS REGISTER: updateFilteringMode', worksheet.name);
      const nodebouncedF = async (changes) => {
    // Debounce used since each sheet send an event when a filter is added
        console.log('LOADING FILTERS REGISTER: changes', FILTERING_MODE_FILTER, filteringMode, changes, new Date().getTime());

        if (filteringMode === FILTERING_MODE_FILTER) {
          const { worksheets: fWorksheets } = tableau.extensions.dashboardContent.dashboard;
          await loadFilters(fWorksheets, filteringMode);
        }
      };

      const unregisterFilterHandlerFunction = worksheet.addEventListener(
        tableau.TableauEventType.FilterChanged,
        tabelauFilterDebounce(nodebouncedF, 300)
      );
      unregisterHandlerFunctions.push(unregisterFilterHandlerFunction);

      const unregisterHighlightHandlerFunction = worksheet.addEventListener(
        tableau.TableauEventType.MarkSelectionChanged,
        async changes => {
          if (filteringMode === FILTERING_MODE_SELECTION) {
            // See if we can get all filters
            const _filters = await worksheets.reduce(async (acc, worksheet) => {
              const marks = await worksheet.getSelectedMarksAsync();
              //console.log('MARKS: ', marks);
              if (marks.data.length > 0) {
                const { data, columns } = marks.data[marks.data.length - 1];
                if (columns.length > 0) {
                  //const regex = /\w*\((.*)\)/gi;
                  const regex = /([a-zA-Z_\ \(\d\)]+)/gi;
                  const filters = columns
                    .map((column, idx) => {
                      const values = data.reduce((acc, cur) => {
                        if (cur.length > 0) {
                          acc.push(cur[idx].value);
                        }
                        return acc;
                      }, []);
                      return {
                        column: column.fieldName,
                        dataType: column.dataType,
                        include: values,
                      };
                    })
                    .filter(item => {
                      //return !item.column.match(regex);
                      return item.column.match(regex);
                    });
                  const arr = await acc;
                  return arr.concat(filters);
                }
              }
              return await acc;
            }, []);
            console.info(FILTERING_MODE_SELECTION, _filters);

            setFilters(_filters);
          }
        }
      );
      unregisterHandlerFunctions.push(unregisterHighlightHandlerFunction);
    });

    map.getControls().forEach(function(control) {
      if (control instanceof Geocoder) {
        console.dir(control);
        console.log('ADDING GEOCODER EVENT LISTENERS');
        control.on('addresschosen', function (evt) {     
            control.getSource().clear();
          });
      }
    });

  }, []);

  // Initilize everything on load
  useEffect(() => {
    // Use API to get datasource tables and connection info
    const configure = () => {
      tableau.extensions.ui
        .displayDialogAsync(`${window.location.href}configure`, '', {
          height: 550,
          width: 420,
        })
        .then(closePayload => {
          // Check for saved endpoint
          let endpoint = tableau.extensions.settings.get('endpoint');
          if (endpoint != null) {
            setEndpoint(endpoint);
          }

          // Check for saved username
          let username = tableau.extensions.settings.get('username');
          if (username != null) {
            setUsername(username);
          }

          // Check for saved password
          let password = tableau.extensions.settings.get('password');
          if (password != null) {
            setPassword(password);
          }

          // Check for saved basemap url
          let basemapUrl = tableau.extensions.settings.get('basemapUrl');
          if (basemapUrl != null) {
            setBasemapUrl(basemapUrl);
          }

          // Check for saved demo mode
          let demoMode = tableau.extensions.settings.get('demoMode');
          if (demoMode != null) {
            setDemoMode(demoMode);
          }

          // Check for saved filtering mode
          let filteringMode = tableau.extensions.settings.get('filteringMode');
          if (filteringMode != null) {
            updateFilteringMode(filteringMode);
            setFilteringMode(filteringMode);
          }
          console.log('after set filtering mode', filteringMode);
        })
        .catch(error => {
          console.error('error', error);
        });
    };

    tableau.extensions.initializeAsync({ configure: configure }).then(() => {
      let endpoint = tableau.extensions?.settings?.get('endpoint') || null;
      console.log('initializing with endpoint: ' + endpoint);
      if(!endpoint) {
        configure();
      }
      reloadConfigRef.current = true;
      
      // ensure that spatial parameters are reset to default values
      updateParameters({
        'wkt': 'POLYGON((-180 -90, 180 -90, 180 90, -180 90, -180 -90))',
        'wktviewport': 'POLYGON((-180 -90, 180 -90, 180 90, -180 90, -180 -90))'
      });
      savedWktValue = 'POLYGON((-180 -90, 180 -90, 180 90, -180 90, -180 -90))';

      loadConfig();

      map.addOverlay(
        new OlOverlay({
          id: 'info',
          element: document.getElementById('info'),
          autoPan: true,
          autoPanAnimation: {
            duration: 250,
          },
          positioning: 'center-center',
          autoPanMargin: 70,
        })
      );
    });

    map.setTarget('map');
    return () => map.setTarget(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const mergedDatasources = useMemo(
    _ => {
      let mergedSources = [];
      if (demoMode === DEMO_MODE_ENABLED) {
        console.log('datasources: ', datasources);
        mergedSources = [...DEMO_DATASOURCES, ...datasources];
        return mergedSources;
      }
      return datasources;
    },
    [demoMode, datasources]
  );

  const updateLayerView = (layer, viewName) => {
    let newLayers = [...mapLayers];
    // find layer with matching id
    const index = newLayers.findIndex(item => item.id === layer.id);
    if (index == -1) {
      console.error('Could not find layer with id: ', layer.id, layer.label);
      return;
    }
    const newLayer = {
      ...newLayers[index],
      kineticaSettings: {
        ...newLayers[index].kineticaSettings,
        view: viewName,
      },
    };
    setMapLayers((oldLayers) => {
      const newLayers = [...oldLayers];
      newLayers[index] = newLayer;
      return newLayers;
    });

  };

  const updateLayer = (layer) => {
    console.log('updating layer: ', layer, selectedLayer.op, selectedLayer.index);
    let newLayers = [...mapLayers];
    const origLayer = mapLayers[selectedLayer.index];

    if (layer?.id !== '0000') {
      layer.kineticaSettings = {
        ...layer.kineticaSettings,
      };
      // Make sure all other layers use the baseLayers baseTable and view
      if (layer.layerType === LAYER_TYPES.K_WMS_BASE_LAYER.value) {
        layer.kineticaSettings.baseTable = mapLayers[0].kineticaSettings.baseTable;
        layer.kineticaSettings.view = mapLayers[0].kineticaSettings.view;
      }
    }

    // layer settings
    if (selectedLayer.op === 'edit') {
      newLayers[selectedLayer.index] = layer;
    } else if (selectedLayer.op === 'add') {
      newLayers.push(layer);
    } 

    if (origLayer?.id === '0000') {
      // If there are spatial column changes, we need to sync the spatial changes to all other layers for now
      if (origLayer.kineticaSettings.longitude != layer.kineticaSettings.longitude || origLayer.kineticaSettings.latitude != layer.kineticaSettings.latitude || origLayer.kineticaSettings.wkt != layer.kineticaSettings.wkt) {
        newLayers = newLayers.map((newLayer) => {
          return {
            ...newLayer,
            kineticaSettings: {
              ...newLayer.kineticaSettings,
              latitude: newLayer.layerType === LAYER_TYPES.K_WMS_BASE_LAYER ? layer.kineticaSettings.latitude : newLayer.kineticaSettings.latitude,
              longitude: newLayer.layerType === LAYER_TYPES.K_WMS_BASE_LAYER ? layer.kineticaSettings.longitude : newLayer.kineticaSettings.longitude,
              wkt: newLayer.layerType === LAYER_TYPES.K_WMS_BASE_LAYER ? layer.kineticaSettings.wkt : newLayer.kineticaSettings.wkt,
              dataType: newLayer.layerType === LAYER_TYPES.K_WMS_BASE_LAYER ? layer.kineticaSettings.dataType : newLayer.kineticaSettings.dataType,
            }
          }
        });
      }
    }

    // update layersFilterText
    const layerIds = newLayers.map(layer => layer.id);
    layerIds.sort();
    let newLayersFilterText = '';
    layerIds.forEach(id => {
      const kineticaSettings = newLayers.find(layer => layer.id === id).kineticaSettings;
      if (kineticaSettings?.filter?.enabled) {
        if (newLayersFilterText.length > 0) {
          newLayersFilterText += ' AND ';
        }
        newLayersFilterText += `${kineticaSettings.filter.text}`;
      }
    });
    setLayersFilterText(newLayersFilterText);

    // finally... update the map layers
    setMapLayers(newLayers);
    cachedSettings.push({
      name: 'mapLayers',
      value: JSON.stringify(newLayers),
      op: 'set'
    });
  };


  const updateLayers = (newLayers) => {
    setMapLayers(newLayers);
    cachedSettings.push({
      name: 'mapLayers',
      value: JSON.stringify(newLayers),
      op: 'set'
    });
  };

  useEffect(() => {
    if (isConfigLoaded) {
      // base layer must have lng/lat/wkt/baseTable/view at a minimum
      const baseLayer = mapLayers.find(layer => layer.id === '0000');
      if (!((baseLayer?.kineticaSettings?.longitude && baseLayer?.kineticaSettings?.latitude) || baseLayer?.kineticaSettings?.wkt )) {
        setSelectedLayer({op: 'edit', index: 0});
      }
    }
  }, [isConfigLoaded]);

  useEffect(() => {
    console.log('mapLayers changed: ', map.getLayers());

    // Get a reference to the OpenLayers vector tiles layer
    const vectorTilesLayer = map.getLayers().getArray()[0];

    // Get the map layer IDs in the desired order
    const orderedLayerIds = mapLayers.map(layer => layer.id);
    console.log('orderedLayerIds: ', orderedLayerIds);

    // Create a new array to hold the layers in the correct order
    const orderedLayers = [vectorTilesLayer];

    // Loop through the ordered layer IDs and find the matching layer in the OpenLayers map
    orderedLayerIds.forEach(layerId => {
      const layer = map.getLayers().getArray().find(layer => layer['id'] === layerId)
      if (layer) {
        orderedLayers.push(layer);
      }
    });
    console.log('orderedLayers: ', orderedLayers);

    // remove all layers from map
    map.getLayers().clear();

    // Set the OpenLayers map layers to the ordered array
    map.setLayerGroup(new Group({
      layers: orderedLayers
    }));

    // reregister for multimap when mapLayers change
    multimapUnregisterHandlerFunctions.forEach((unregisterHandlerFunction) => {
      unregisterHandlerFunction();
    });
    multimapUnregisterHandlerFunctions = [];
    registerMultimapParameterListener();

  }, [mapLayers]);

  useEffect(() => {
    if (!hasRegisteredForMultimap) {
      const { latitude, longitude, wkt } = mapLayers[0].kineticaSettings;
      if (
        !(gpudb &&
          selectedDatasource &&
        ((longitude !== '' && latitude !== '') || wkt !== ''))
      ) {
        return;
      }

      registerMultimapParameterListener();
      setHasRegisteredForMultimap(true);
    }
  }, [gpudb, selectedDatasource, mapLayers[0].kineticaSettings]);

  // get column bin range
  const getColumnBinRange = async (fieldName) => {
    if (selectedDatasource) {
      const { worksheets } = tableau.extensions.dashboardContent.dashboard;
      for (const worksheet of worksheets) {
        const summaryData = await worksheet.getSummaryDataAsync();
        console.log('getColumnBinRange: summaryData: ', worksheet.name, summaryData);

        // find index of summaryData.columns that contains 'fieldName (bin)'
        const binIndex = summaryData.columns.findIndex((obj) => obj.fieldName.includes(fieldName));
        console.log('binIndex: ', binIndex);

        if (binIndex === -1) {
          continue;
        }

        let range;
        const dataValue = summaryData.data[0][binIndex];
        console.log('dataValue: ', dataValue);
        const formattedValue = parseFloat(dataValue.formattedValue);
        const nativeValue = parseFloat(dataValue.nativeValue);
        if (isNaN(formattedValue) || isNaN(nativeValue)) {
          continue;
        }

        range = async() => {
          if (nativeValue === 0) {
            return await findColumnBinRangeByFilter(fieldName);
          } else {
            return Math.abs(formattedValue / nativeValue);
          }
        };
        const _range = await range();
        console.log('range: ', _range);

        // add [filter.fieldName]: bins to binRanges
        const newBinRanges = {...binRanges};
        newBinRanges[`${fieldName} (bin)`] = [0, _range, nativeValue];
        setBinRanges(newBinRanges);
        return newBinRanges;
      }
    }
  };

  const findColumnBinRangeByFilter = async (fieldName) => {
    if (selectedDatasource) {
      const { worksheets } = tableau.extensions.dashboardContent.dashboard;
      for (const worksheet of worksheets) {
        const filters = await worksheet.getFiltersAsync();
        console.log('findColumnBinRangeByFilter: filters: ', worksheet.name, filters);

        // find index of filters that contains 'fieldName (bin)'
        const binIndex = filters.findIndex((obj) => obj.fieldName.includes(fieldName));
        console.log('binIndex: ', binIndex);

        if (binIndex === -1) {
          continue;
        }

        let range;
        const dataValue = filters[binIndex];
        console.log('dataValue: ', dataValue);
        if (!dataValue?.appliedValues?.[0]) {
          continue;
        }
        const formattedValue = parseFloat(dataValue.appliedValues[0].formattedValue);
        const nativeValue = parseFloat(dataValue.appliedValues[0].nativeValue);
        if (isNaN(formattedValue) || isNaN(nativeValue)) {
          continue;
        }

        if (nativeValue === 0) {
          console.log('binRanges: ', binRanges);
          if (binRanges?.[`${fieldName} (bin)`]) {
            range = Math.abs(binRanges[`${fieldName} (bin)`][1] - binRanges[`${fieldName} (bin)`][0]);
          } else {
            setError('Warning', `Unable to find bin ranges for ${fieldName} (bin).  Please select a different bin, before reselecting this one.`);
            return;
          }
        } else {
          range = Math.abs(formattedValue / nativeValue);
        }
        
        console.log('range: ', range);
        return range;
        
      }
    }
  };

  const handleErrorMsgClick = (func) => {
    try {
      func();
    } catch (err) {
      console.error(err);
    }
  };

  const handleFileUpload = (event) => {
    console.log('handleFileUpload: ', event.target.files[0], twbFile);
    const file = event.target.files[0];
    inputFile.current.value = null; // clear the input field
    setTwbFile(file);
  };

  // render starts here 
  console.log("RENDER: selectedLayer: ", selectedLayer);
  console.log("RENDER: mapLayers: ", mapLayers);
  console.log("RENDER: showLayersPanel: ", showLayersPanel);
  
  if (cachedSettings.length > 0) {
    asyncSaveSettings();
  }

  // Render all kinetica wms layers
  const wmsApiUrl = selectedDatasource?.apiUrl ?? endpoint + '/wms';
  const layersRender = (map && wmsApiUrl && selectedDatasource && 
                        username && password && mapLayers ?
    mapLayers.map((lyr, index) => <KWmsOlLayer
      key={lyr.id}
      index={index}
      label={lyr.label}
      id={lyr.id}
      map={map}
      kineticaSettings={lyr.kineticaSettings}
      visible={lyr.visible}
      opacity={lyr.opacity}
      minZoom={lyr.minZoom}
      maxZoom={lyr.maxZoom}
      wmsApiUrl={wmsApiUrl}
      authUsername={username}
      authPassword={password}
      setError={setError}
      datasource={selectedDatasource}
    />) : null
  );

  // format error message
  const formatErrorMessage = (error) => {
    let lines = [];
    for (const key in error) {
      if (error[key] && error[key].customHtml) {
        lines.push(error[key].customHtml);
      } else if (error[key] && error[key].text && error[key].text.length > 0) {
        lines.push(<div key={key} style={{width: '95%'}}>
          {key}: {error[key].text}</div>);
      }
    }
    return lines;
  };
  

  return (
    <div>
      <div className="config_header">
        <Button
          variant='light'
          onClick={() => {
            setShowLayersPanel(!showLayersPanel);
            closeInfo();
          }}
          style={{ width: '150px' }}
        >
          <Stack /> {showLayersPanel ? '  Hide ' : '  Show ' } Layers
        </Button>
        {count >= 0 && (
          <>
            <br />
            <Badge
              variant="secondary"
              style={{ width: '150px', padding: '5px', fontSize: '11px' }}
            >
              Records: {thousands_separators(count)}
            </Badge>
          </>
        )}
        <br />
        <Badge style={{ width: '150px', padding: '5px', fontSize: '11px', color: '#f5f5f5'}}>
          Powered by{' '}
          <a
            href="https://www.kinetica.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Kinetica
          </a>
        </Badge>
      </div>
      <div className="draw">
      <br/>
        <DrawButton
          map={map}
          drawMode={cursorMode === CURSOR_FREEHAND_DRAW}
          drawType={drawType}
          drawUndo={drawUndo}
          setDrawMode={(isDrawMode) => {
            if (isDrawMode) {
              setCursorMode(CURSOR_FREEHAND_DRAW);
            } else {
              setCursorMode(CURSOR_INFO);
            }
          }}
          setDrawType={(dType) => {
            setDrawType(dType);
          }}
          setDrawUndo={(drawUndoRet) => {
            if (drawUndoRet) {
              DrawLayer.getSource().clear();
              setDrawnFeatures([]);

              updateParameters({'wkt': 'POLYGON((-180 -90, 180 -90, 180 90, -180 90, -180 -90)) '});
              setDrawUndo(true);
              return;
            }
          }} />
      </div>
      <div className="viewport">
      <br/>
        <ViewportButton
            filterByViewportMode={filterByViewportMode}
            setFilterByViewportMode={setFilterByViewportMode} />
      </div>
      <div className="upload-calc-field">
        <br />
        <CalcFieldUploadButton
          handleUploadTwbFile={handleUploadTwbFile} />
      </div>
      {/* TODO: Info can come from a layer's datasource and calculated fields. Pass in a context object instead? */}
      <Info
        id="info"
        gpudb={gpudb}
        radius={infoRadius}
        infoLayers={infoLayers}
        columns={selectedDatasource?.table?.columns || []}
        calculatedField={mapLayers?.[0].kineticaSettings?.cbStyleOptions?.calculatedField || null}
        calculatedFieldName={mapLayers?.[0].kineticaSettings?.cbStyleOptions?.calculatedFieldName || null}
        width={width}
        coordinate={infoCoordinate}
        popupType={mapLayers[0].popupType}
        popupTemplate={mapLayers[0].popupTemplate}
        close={closeInfo}
      />
      <div id="map" className="map"></div>
      {layersRender}
      <OlDrawer map={map} drawType={drawType} drawComplete={handleDrawMapClick} />
      {showLayersPanel && (
      <LayersPanel
        mapLayers={mapLayers}
        setMapLayers={setMapLayers}
        setSelectedLayer={setSelectedLayer}
        selectedLayer={selectedLayer}
        datasource={selectedDatasource}
        updateLayer={updateLayer}
        updateLayers={updateLayers}
      />
      )}
      {selectedLayer.op === 'edit' && selectedLayer.index >= 0 && (
      <MapSettings
        // Global
        gpudb={gpudb}
        selectedDatasource={selectedDatasource}
        datasources={mergedDatasources}
        setDatasource={setSelectedDatasource}
        saveSettings={saveSettings}
        setError={setError}
        layer={mapLayers[selectedLayer.index]}
        updateLayer={updateLayer}
        sqlBase={sqlBase}
          kTableList={kTableList}
        close={() => {
          setSelectedLayer({op: 'none', index: 0})
          closeInfo();
        }}
      />)}
      {selectedLayer.op === 'add' && (
      <MapSettings
        // Global
        gpudb={gpudb}
        selectedDatasource={selectedDatasource}
        datasources={mergedDatasources}
        setDatasource={setSelectedDatasource}
        saveSettings={saveSettings}
        setError={setError}
        layer={{}}
        updateLayer={updateLayer}
        sqlBase={sqlBase}
          kTableList={kTableList}
        close={() => {
          setSelectedLayer({op: 'none', index: 0})
          closeInfo();
        }}
      />)}
      {selectedLayer.op === 'edit' && selectedLayer.index === -1 && (
      <MapSettings
        // Global
        gpudb={gpudb}
        selectedDatasource={selectedDatasource}
        datasources={mergedDatasources}
        setDatasource={setSelectedDatasource}
        saveSettings={saveSettings}
        setError={setError}
        updateLayer={updateLayer}
        sqlBase={sqlBase}
          kTableList={kTableList}
        close={() => {
          setSelectedLayer({op: 'none', index: 0})
          closeInfo();
        }}
      />)}
      <MissingParameters
        show={areParametersMissing}
        close={() => {
          setAreParametersMissing(false);
        }}
      />
      {errorMsg && formatErrorMessage(errorMsg).length > 0 && (
        <div
          style={{
            backgroundColor: errorMsg['Warning'] ? '#d8a723' : '#ff000099',
            color: '#ffffff',
            position: 'absolute',
            margin: '11px 150px 20px 75px',
            padding: '7px 10px',
            top: 0,
            borderRadius: 3,
            width: 'calc(100% - 250px)',
          }}
          onClick={() => {
            console.log('error msg click', errorMsg);
            handleErrorMsgClick(errorMsg.func);
          }}
        >
        <button
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            color: '#ffffff',
            position: 'absolute',
            top: '5px',
            right: '5px',
            cursor: 'pointer',
          }}
          onClick={() => {
            setErrorMsg({}); // clear all errors
          }}
        >
        X
        </button>
          {formatErrorMessage(errorMsg)}
        </div>
      )}
        <div hidden>
          <a href="#" onClick={() => inputFile.current.click()} >Sync Metadata</a>
          <input
            type="file"
            ref={inputFile}
            onChange={handleFileUpload}
          />
        </div>
        {twbFile && (
        <TwbContext twb={twbFile} setError={setError} setTwbFileDetails={handleTwbFileProcessed} />
        )}
    </div>
  );
}

export default Map;
