
import React from 'react';
import { Button } from 'react-bootstrap';
import { Upload } from 'react-bootstrap-icons';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export const CalcFieldUploadButton = (props) => {
    const renderTooltip = (props) => (
        <Tooltip  {...props}>
            Sync calculated fields
        </Tooltip>
    );

    return (
        <>
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
            >
                <Button
                    variant={'dark'}
                    onClick={() => {
                        props.handleUploadTwbFile();
                    }}
                    style={{ width: '50px' }}
                    overlay={renderTooltip}
                >
                    <Upload />
                </Button>
            </OverlayTrigger>
        </>
    );
};