import React, { useEffect, useState } from 'react';
import {
  CaretLeftFill,
  CaretRightFill,
  X,
} from 'react-bootstrap-icons';
import {
  Dropdown,
  DropdownButton,
  Form,
} from 'react-bootstrap';
import {
  INFO_MODAL_FETCH_LIMIT,
} from '../constants';
import Handlebars from "handlebars";
import { d3Format } from '../util';

const InfoTemplate = (props) => {
  const {
    pageNumber,
    displayedPageNumber,
    records,
    selectedLayer,
    localInfoLayers,
    isLoading,
    handleLayerSelected,
    decrementPageNumber,
    incrementPageNumber,
    setDisplayedPageNumber,
    setPageNumber,
    popupTemplate,
    coordinate,
    close
  } = props;

  const [recordNumber, setRecordNumber] = useState(0);

  useEffect(() => {
    setRecordNumber(0);
  }, [pageNumber, records, selectedLayer, coordinate]);

  const decrementRecordNumber = () => {
    if (recordNumber > 0) {
      const newRecordNumber = recordNumber - 1;
      setRecordNumber(newRecordNumber);
      // Calculate the page number
      const newPageNumber = Math.floor(newRecordNumber / INFO_MODAL_FETCH_LIMIT) + 1;
      if (pageNumber != newPageNumber) {
        setPageNumber(newPageNumber);
      }
    }
  };

  const incrementRecordNumber = () => {
    if (recordNumber + 1 < records?.total_number_of_records) {

      const newRecordNumber = recordNumber + 1;
      setRecordNumber(newRecordNumber);
      // Calculate the page number
      const newPageNumber = Math.floor(newRecordNumber / INFO_MODAL_FETCH_LIMIT) + 1;
      console.log(newPageNumber, pageNumber);
      if (pageNumber != newPageNumber) {
        setPageNumber(newPageNumber);
      }
    }
  };

  const pageRecordNumber = recordNumber % INFO_MODAL_FETCH_LIMIT;
  Handlebars.registerHelper('d3Format', d3Format);
  const template = Handlebars.compile(popupTemplate ? popupTemplate : '');
  const popupHtml = template(records?.data[pageRecordNumber]);

  const layerSelectOptsDropDown = localInfoLayers?.map((lyr) => (<Dropdown.Item
    key={lyr.id}
    onClick={() => { handleLayerSelected(lyr.id); }}
    value={lyr.id}>
    {lyr.label}
  </Dropdown.Item>));

  const loadingPanel = isLoading ? <div style={{
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    opacity: '0.7',
    backgroundColor: '#fff',
    zIndex: '99',
    fontWeight: "bold",
  }}>Loading....</div> : null;

  return (
    <div>
      {loadingPanel}

      {!isLoading && selectedLayer && (
        <>
          <div style={{ marginBottom: '5px' }}>
            <div className="info-layer-selection">
              <DropdownButton
                style={{ width: "100%", minWidth: "200px", maxWidth: "400px" }}
                id="select-info-layer"
                variant="dark"
                title={selectedLayer.label}
                size="sm"
              >
                {layerSelectOptsDropDown}
              </DropdownButton>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {records && <div id="displaying">
                Displaying <strong>{recordNumber + 1} / {records?.total_number_of_records}</strong>
              </div>}

              <span style={{ float: 'right' }}>
                <X
                  onClick={_ => close()}
                  size={18}
                  style={{
                    position: 'absolute',
                    top: '7px',
                    right: '5px',
                    color: '#ca2c92',
                    cursor: 'pointer',
                  }}
                />
              </span>

              {records && <div
                id="page_control"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <CaretLeftFill
                  onClick={_ => {
                    decrementRecordNumber();
                  }}
                />
                <Form.Control
                  as="input"
                  min="1"
                  disabled={true}
                  max={parseInt(records?.total_number_of_records / INFO_MODAL_FETCH_LIMIT) + 1}
                  size="sm"
                  style={{ height: "14px", width: "60px", textAlign: "center" }}
                  value={recordNumber + 1}
                />
                <CaretRightFill
                  onClick={_ => {
                    incrementRecordNumber();
                  }}
                />
              </div>}

              {coordinate && (
                <span style={{ float: 'right', marginRight: '16px' }}>
                  Coordinate:{' '}
                  <strong>
                    {coordinate.map(val => val.toPrecision(8)).join(', ')}
                  </strong>
                </span>
              )}
            </div>
          </div>
          {records == null && <div className="info_grid info_grid_no_records">
            No Records
          </div>}
          {records && <div className="info_grid" style={{ "overflowY": "auto" }}>
            <div style={{ "height": "100%" }} dangerouslySetInnerHTML={{ __html: popupHtml }}></div>
          </div>}
        </>
      )}
    </div>
  );
};

export default InfoTemplate;
