import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Map from './Map';
import Configure from './Configure';

const App = () => {

  const [reloadConfig, setReloadConfig] = React.useState(false);

  const setShouldReload = (value) => {
    setReloadConfig(value);
    render();
  }

  const render = () => {
    return(
      <Router>
        <Switch>
          <Route path="/configure" exact>
            <Configure setShouldReload={setShouldReload} />
          </Route>
          <Route path="/map" exact>
            <Map reloadConfig={reloadConfig} />
          </Route>
          <Route path="/">
            <Map reloadConfig={reloadConfig} />
          </Route>
        </Switch>
      </Router>
    );
  };

  return render();
};

export default App;
