import React, { useState, useEffect, useMemo } from 'react';
import {
  Modal,
  Form,
  Row,
  Col,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import { 
  ArrowCounterclockwise,
  Funnel,
  FunnelFill,
} from 'react-bootstrap-icons';
import { ChromePicker } from 'react-color';
import {
  RENDER_TYPE_LABELS,
  DATA_TYPE_LABELS,
  COLORMAPS,
  DEFAULT_COLORMAP,
  DEFAULT_FILL_COLOR,
  DEFAULT_BORDER_COLOR,
  DEFAULT_OPACITY,
  DEFAULT_BLUR_RADIUS,
  DEFAULT_HEATMAP_ATTR,
  DEFAULT_POINT_SIZE,
  DEFAULT_MAX_ZOOM,
  DEFAULT_MIN_ZOOM,
  LAYER_TYPES,
  DEFAULT_ENABLE_POPUP,
  DEFAULT_POPUP_TYPE,
  DEFAULT_POPUP_TEMPLATE,
  POPUP_TYPE_TEMPLATE,
  POPUP_TYPE_GRID,
} from '../constants';
import ClassbreakStylingOptions from './ClassbreakStylingOptions';
import CustomMeasureEditor from './CustomMeasureEditor';
import FilterEditor from './FilterEditor';
import { gradient } from './ColorGradient';

function MapSettings(props) {
  const { datasources, sqlBase, close, kTableList } = props;
  console.log('MapSettings: props: ', props);

  // Global
  const [datasource, setDatasource] = useState(props.selectedDatasource);
  const [showFillPicker, setShowFillPicker] = useState(false);
  const [showBorderPicker, setShowBorderPicker] = useState(false);
  const [isHeatmapBehaviorEditorOpen, setIsHeatmapBehaviorEditorOpen] = useState(false);
  const [isFilterEditorOpen, setIsFilterEditorOpen] = useState(false);

  const generateRandomAlphaNumeric = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 4; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  // Per Layer
  const [id] = useState(props.layer?.id || generateRandomAlphaNumeric());
  const [label, setLabel] = useState(props.layer?.label || 'New Layer');
  const [visible, setVisible] = useState(props.layer?.visible === undefined ? true : props.layer?.visible);
  const [showLegend, setShowLegend] = useState(props.layer?.showLegend === undefined ? true : props.layer?.showLegend);
  const [opacity, setOpacity] = useState(parseInt(props.layer?.opacity) || DEFAULT_OPACITY);
  const [popupType, setPopupType] = useState(props.layer?.popupType || DEFAULT_POPUP_TYPE);
  const [popupTemplate, setPopupTemplate] = useState(props.layer?.popupTemplate || DEFAULT_POPUP_TEMPLATE);
  const [enablePopup, setEnablePopup] = useState(props.layer?.enablePopup || DEFAULT_ENABLE_POPUP);
  const [maxZoom, setMaxZoom] = useState(parseInt(props.layer?.maxZoom) || DEFAULT_MAX_ZOOM);
  const [minZoom, setMinZoom] = useState(parseInt(props.layer?.minZoom) || DEFAULT_MIN_ZOOM);
  const [layerType, setLayerType] = useState(props.layer?.layerType || LAYER_TYPES.K_WMS_BASE_LAYER.value);
  const [selectedBaseTable, setSelectedBaseTable] = useState(props.layer?.kineticaSettings?.baseTable);

  let newSelectedTableInfo = null;
  if (props.layer?.layerType === LAYER_TYPES.K_WMS_TABLE.value) {
    const fTableInfo = kTableList.filter((table1) => table1.full_name === selectedBaseTable);
    if (fTableInfo?.length > 0) {
      newSelectedTableInfo = fTableInfo[0];
    }
  }
  const [selectedTableInfo, setSelectedTableInfo] = useState(newSelectedTableInfo);
  const [renderType, setRenderType] = useState(props.layer?.kineticaSettings?.renderType || 'heatmap');
  const [dataType, setDataType] = useState(props.layer?.kineticaSettings?.dataType || 'point');
  const [longitude, setLongitude] = useState(props.layer?.kineticaSettings?.longitude || '');
  const [latitude, setLatitude] = useState(props.layer?.kineticaSettings?.latitude || '');
  const [wkt, setWkt] = useState(props.layer?.kineticaSettings?.wkt || '');
  const [blurRadius, setBlurRadius] = useState(parseInt(props.layer?.kineticaSettings?.blurRadius) || DEFAULT_BLUR_RADIUS);
  const [heatmapAggregation, setHeatmapAggregation] = useState(props.layer?.kineticaSettings?.heatmapAggregation || 'COUNT');
  const [heatmapAttr, setHeatmapAttr] = useState(props.layer?.kineticaSettings?.heatmapAttr || DEFAULT_HEATMAP_ATTR);
  const [pointSize, setPointSize] = useState(parseInt(props.layer?.kineticaSettings?.pointSize) || DEFAULT_POINT_SIZE);
  const [colormap, setColormap] = useState(props.layer?.kineticaSettings?.colormap || DEFAULT_COLORMAP);
  const [fillColor, setFillColor] = useState(props.layer?.kineticaSettings?.fillColor || DEFAULT_FILL_COLOR);
  const [borderColor, setBorderColor] = useState(props.layer?.kineticaSettings?.borderColor || DEFAULT_BORDER_COLOR);
  const [width, setWidth] = useState(parseInt(props.layer?.kineticaSettings?.width) || 0);
  const [filter, setFilter] = useState(props.layer?.kineticaSettings?.filter || {enabled: false});
  const [cbStyleOptions, setCBStyleOptions] = useState({
    column: datasource?.table?.columns[0]?.name || '',
    binCount: 4,
    allStyleColors: null,
    allStyleRanges: null,
    allStyleShapes: null,
    allStyleSizes: null,
    allStylePointAttributes: null,
    allStylePointAttributeSizes: null,
    colorRamp: ['fd191f', 'e06100', 'bc8500', '949e00', '6aaf35', '34b36a', '029496', '00b0b2', '00a2d0', '008ef5'],
    otherColor: '888888',
    selectedTheme: 'Spectral',
    columnValueOptions: [''],
    hasChanges: false,
    colorOpen: false,
    pickerActiveIndex: null,
    pickerColor: null,
    ...props.layer?.kineticaSettings?.cbStyleOptions,
  });
  const [cbColumnCache, setCBColumnCache] = useState({
    ...props.layer?.kineticaSettings?.cbColumnCache
  });

  const handleStyleChanged = (stylingOptions) => {
    console.log('MapSettings.handleStyleChanged:');
    console.dir(stylingOptions);
    setCBStyleOptions({
      ...stylingOptions,
      hasChanges: false,
    });
  };

  const updateConfig = async() => {
    const newLayer = {};
    newLayer.id = id;
    newLayer.label = label;
    newLayer.visible = visible;
    newLayer.showLegend = showLegend;
    newLayer.opacity = parseInt(opacity);
    newLayer.minZoom = parseInt(minZoom);
    newLayer.maxZoom = parseInt(maxZoom);
    newLayer.popupType = popupType;
    newLayer.popupTemplate = popupTemplate;
    newLayer.enablePopup = enablePopup;
    newLayer.layerType = layerType;
    
    newLayer.kineticaSettings = {};
    if (datasource?.table?.schema && datasource?.table?.name) {
      newLayer.kineticaSettings.baseTable = layerType === LAYER_TYPES.K_WMS_TABLE.value ? selectedBaseTable : props.layer?.kineticaSettings?.baseTable || `${datasource.table.schema}.${datasource.table.name}`;
      newLayer.kineticaSettings.view = layerType === LAYER_TYPES.K_WMS_TABLE.value ? selectedBaseTable : props.layer?.kineticaSettings?.view || `${datasource.table.schema}.${datasource.table.name}`;
    } else {
      newLayer.kineticaSettings.baseTable = layerType === LAYER_TYPES.K_WMS_TABLE.value ? selectedBaseTable : props.layer?.kineticaSettings?.baseTable || `${datasource.table.schema}.${datasource.table.name}`;
      newLayer.kineticaSettings.view = layerType === LAYER_TYPES.K_WMS_TABLE.value ? selectedBaseTable : props.layer?.kineticaSettings?.view || `${datasource.table.schema}.${datasource.table.name}`;
    }

    newLayer.kineticaSettings.renderType = renderType;
    newLayer.kineticaSettings.blurRadius = parseInt(blurRadius);
    newLayer.kineticaSettings.heatmapAggregation = heatmapAggregation;
    newLayer.kineticaSettings.heatmapAttr = heatmapAttr;
    newLayer.kineticaSettings.pointSize = parseInt(pointSize);
    newLayer.kineticaSettings.colormap = colormap;
    newLayer.kineticaSettings.fillColor = fillColor;
    newLayer.kineticaSettings.borderColor = borderColor;
    newLayer.kineticaSettings.width = parseInt(width);
    newLayer.kineticaSettings.filter = filter;

    newLayer.kineticaSettings.dataType = dataType;
    if (dataType === 'point') {
      newLayer.kineticaSettings.longitude = longitude;
      newLayer.kineticaSettings.latitude = latitude;
      newLayer.kineticaSettings.wkt = '';
    } else if (dataType === 'geo') {
      newLayer.kineticaSettings.longitude = '';
      newLayer.kineticaSettings.latitude = '';
      newLayer.kineticaSettings.wkt = wkt;
    }

    newLayer.kineticaSettings.cbStyleOptions = {};
    newLayer.kineticaSettings.cbStyleOptions = cbStyleOptions;
    newLayer.kineticaSettings.cbColumnCache = cbColumnCache;

    let settings = {
      'wkt': wkt,
      'longitude': longitude,
      'latitude': latitude,
      'dataType': dataType,
    };

    if (datasource) {
      props.setDatasource(datasource);
    } else {
      props.setDatasource(undefined);
    }

    props.saveSettings(settings);
    props.updateLayer(newLayer);
    close();
  };

  const findColumnMatch = (tableInfo, matches) => {
    return Object.keys(matches).reduce((acc, cur) => {
      if (acc === '') {
        let match;
        if (cur === 'properties') {
          match = tableInfo.columns.find(column => {
            return matches[cur].find(key => column.properties?.includes(key));
          });
        } else if (cur === 'includes') {
          match = tableInfo.columns.find(column => {
            return matches[cur].find(key =>
              column.name.toLowerCase().includes(key)
            );
          });
        } else if (cur === 'startsWith') {
          match = tableInfo.columns.find(column => {
            return matches[cur].find(key =>
              column.name.toLowerCase().startsWith(key)
            );
          });
        }
        acc = match ? match.name : '';
      }
      return acc;
    }, '');
  };

  const resetDatasource = datasource => {
    setDatasource(datasource);
    resetTableInfo(props.layer.layerType === LAYER_TYPES.K_WMS_BASE_LAYER.value ? datasource?.table : selectedTableInfo);
  };

  const resetTableInfo = tableInfo => {
    // Intelligently preselect columns
    if (tableInfo?.columns) {
      // Match WKT
      const wkt = findColumnMatch(tableInfo, {
        includes: ['wkt', 'geometry', 'geom'],
        startsWith: [],
        properties: ['wkt'],
      });
      console.log('resetDatasource wkt: ', wkt);
      setWkt(wkt);

      if (wkt !== '') {
        setDataType('geo');
        setLongitude('');
        setLatitude('');
      } else {
        setDataType('point');

        // Match longitude
        const longitude = findColumnMatch(tableInfo, {
          includes: ['longitude', 'long', 'lon'],
          startsWith: ['x'],
        });
        setLongitude(longitude);

        // Match latitude
        const latitude = findColumnMatch(tableInfo, {
          includes: ['latitude', 'lat'],
          startsWith: ['y'],
        });
        setLatitude(latitude);
      }

      // Set initial cbStyling column
      const initialColumn = tableInfo.columns.find((column) => {
        return column.type !== 'long';
      });

      if (initialColumn) {
        setCBStyleOptions(s => ({
          ...s,
          hasChanges: true,
          binCount: 4,
          allStyleColors: null,
          allStyleRanges: null,
          allStyleShapes: null,
          column: initialColumn.name,
        }));
      }
    } else {
      setDataType('point');
      setRenderType('heatmap');
      setLongitude('');
      setLatitude('');
      setWkt('');
    }
  };

  useEffect(() => {
    if (latitude === '' && longitude === '' && wkt === '') {
      resetDatasource(props.selectedDatasource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedDatasource, latitude, longitude, wkt]);

  useEffect(() => {
    if (latitude !== '' || longitude !== '') {
      setWkt('');
    }
  }, [latitude, longitude]);

  useEffect(() => {
    if (wkt !== '') {
      setLatitude('');
      setLongitude('');
    }
  }, [wkt]);

  const updateSelectTableInfo = (tableSearchFor) => {
    if (layerType === LAYER_TYPES.K_WMS_TABLE.value) {
      const foundTableInfo = kTableList.filter((table1) => table1.full_name === tableSearchFor);
      if (foundTableInfo?.length > 0) {
        setSelectedTableInfo(foundTableInfo[0]);
        resetTableInfo(foundTableInfo[0]);
        if (renderType === RENDER_TYPE_LABELS.cb_raster) {
          setRenderType(RENDER_TYPE_LABELS.raster);
        }
      } else {
        console.log('Could not find table: ', tableSearchFor, kTableList);
      }
    }
  };

  // update the default gradient shown based on current colormap selection
  // continues to show the gradient when switching renderTypes
  useEffect(() => {
    const svg = gradient(colormap, 1, true);
    const svgString = new XMLSerializer().serializeToString(svg);
    const el = document.getElementById('selected-colormap-gradient');
    if (el) {
      el.innerHTML = svgString;
    }
  }, [colormap, renderType]);

  const selectableTables = [<option key={''}></option>].concat(
    kTableList.map((kTable, idx) => {
      const { full_name } = kTable;
      return (
        <option key={`${full_name}.${idx}`} value={full_name}>
          {full_name}
        </option>
      );
    })
  );

  const tables = [<option key={''}></option>].concat(
    datasources.map((datasource, idx) => {
      const { schema, name } = datasource?.table;

      let value = '';
      if (schema === '') {
        const tablePattern = /(.*)\s\((.*)\)/;
        const tableMatch = datasource?.name.match(tablePattern);
        if (tableMatch) {
          const table = tableMatch[1];
          const schema = tableMatch[2];
          value = `${schema}.${table}`;
        }
      } else {
        value = `${schema}.${name}`;
      }

      return (
        <option key={`${value}.${idx}`} value={datasource.name}>
          {value} - {datasource.name}
        </option>
      );
    })
  );

  const tableColumns = layerType === LAYER_TYPES.K_WMS_TABLE.value ? selectedTableInfo?.columns : datasource?.table?.columns;
  const geometryColumns = useMemo(() => {
    const tblCols = layerType === LAYER_TYPES.K_WMS_TABLE.value ? selectedTableInfo?.columns : datasource?.table?.columns;
    return tblCols ? [<option key={''}></option>].concat(
      tblCols.filter(col => col.properties.indexOf('wkt') > -1).map(column => (
        <option key={column.name} value={column.name}>
          {column.name}
        </option>
      ))
    ) : [<option key={''}></option>];
  }, [layerType, selectedTableInfo, datasource]);

  const numberColumns = useMemo(() => {
    const tblCols = layerType === LAYER_TYPES.K_WMS_TABLE.value ? selectedTableInfo?.columns : datasource?.table?.columns;
    return tblCols ? [<option key={''}></option>].concat(
      tblCols.filter(col => {
        const numCols = ['int', 'long', 'float', 'double'];
        if (Array.isArray(col.type)) {
          return numCols.some(r => col.type.includes(r)) && col.properties.indexOf('store_only') == -1;
        }

        return numCols.indexOf(col.type) > -1 && col.properties.indexOf('store_only') == -1;
      }).map(column => (
        <option key={column.name} value={column.name}>
          {column.name}
        </option>
      ))
    ) : [<option key={''}></option>];
  }, [layerType, selectedTableInfo, datasource]);

  const classBreakColumns = useMemo(() => {
    const tblCols = layerType === LAYER_TYPES.K_WMS_TABLE.value ? selectedTableInfo?.columns : datasource?.table?.columns;
    return tblCols?.filter(col => col.properties.indexOf('wkt') == -1 && col.properties.indexOf('store_only') == -1);
  }, [layerType, selectedTableInfo, datasource]);

  const columns = tableColumns
    ? [<option key={''}></option>].concat(
      tableColumns.map(column => (
          <option key={column.name} value={column.name}>
            {column.name}
          </option>
        ))
      )
    : [<option key={''}></option>];

  const colormaps = COLORMAPS.map(color => {
    const svg = gradient(color, 1, true);
    const svgString = new XMLSerializer().serializeToString(svg);
    return (
      <Dropdown.Item eventKey={color} 
        key={color}
        onSelect={() => {
          setColormap(color);
        }}
      >
        <div style={{ display: "flex", flexDirection: "row-reverse", alignItems: "center" }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <span
              style={{ display: "inline-block", verticalAlign: "middle", width: "100%", height: "20px", marginBottom: '5px', paddingRight: '2px'}}
              dangerouslySetInnerHTML={{ __html: svgString }}
            >
            </span>
          </div>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginRight: '5px' }}>
            <span>
              {color}
            </span>
          </div>
        </div>
      </Dropdown.Item>
    );
  });

  const handleDatasourceChange = e => {
    const datasource = datasources.find(
      datasource => datasource.name === e.target.value
    );
    resetDatasource(datasource);
  };

  const handleToggleFillPicker = e => {
    setShowFillPicker(!showFillPicker);
  };
  const handleSelectFill = (color, e) => {
    setFillColor(color.hex.replace(/#/g, ''));
  };

  const handleToggleBorderPicker = e => {
    setShowBorderPicker(!showBorderPicker);
  };
  const handleSelectBorder = (color, e) => {
    setBorderColor(color.hex.replace(/#/g, ''));
  };

  const handleResetCustomColors = e => {
    setFillColor(DEFAULT_FILL_COLOR);
    setBorderColor(DEFAULT_BORDER_COLOR);
  };
  const handleResetColormap = e => {
    setColormap(DEFAULT_COLORMAP);
  };

  const popover = {
    position: 'absolute',
    zIndex: '2',
  };
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  };


  // render starts here

  // prevent ugly background panel from appearing on initial load 
  // when endpoint and credentials are not yet set.
  // But, automatically pop-up the correct modal immediately when set.
  if (datasources.length === 0) {
    return (<></>);
  }

  let title = 'Map Settings';
  let showLayerSettings = false;
  if (props.layer) {
    title = 'Map Settings';
    showLayerSettings = true;
  }
  return (
    <Modal
      dialogClassName="custom_dialog"
      show={true}
      onHide={close}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title> 
          {!filter.enabled && <Funnel onClick={() => setIsFilterEditorOpen(true)} />}
          {filter.enabled && <FunnelFill onClick={() => setIsFilterEditorOpen(true)} />}
          {' ' + title}
        </Modal.Title>
        {isFilterEditorOpen && (
        <div id="filter-editor" 
            style={{ 
              position: 'absolute',
              zIndex: 2000, 
              top: '0px', left: '0px', 
              width: '100%', height: '100%', 
              backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <FilterEditor
            title={`Custom Filter: ${label}`}
            show={isFilterEditorOpen}
            filter={filter}
            setFilter={setFilter}
            close={() => setIsFilterEditorOpen(false)}
            sqlBase={sqlBase}
            gpudb={props.gpudb}
          />
        </div>
      )}
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: '10px', minHeight: '270px' }}>
          <Form>
            {!showLayerSettings && props.layer.layerType === LAYER_TYPES.K_WMS_BASE_LAYER.value && (
            <Form.Group as={Row} controlId="configTable">
              <Form.Label column sm="2">
                Table
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  as="select"
                  onChange={handleDatasourceChange}
                  value={datasource ? datasource.name : ''}
                >
                  {tables}
                </Form.Control>
              </Col>
            </Form.Group>
            )}
            {showLayerSettings && (
              <Form.Group as={Row} controlId="layerLabel">
                <Form.Label column sm="2">
                  Label<span style={{ color: 'red' }}> *</span>
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    type="text"
                    value={label}
                    onChange={e => {
                      setLabel(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            )}
            {showLayerSettings && props.layer.id !== '0000' && (
              <Form.Group as={Row} controlId="configTable">
                <Form.Label column sm="2">
                  Reference Table
                </Form.Label>
                <Col sm="10">
                  <DropdownButton
                    style={{ width: "100%", minWidth: "200px", maxWidth: "400px" }}
                    id="layer-type-dropdown"
                    variant="light"
                    title={LAYER_TYPES[layerType].label}
                  >
                    <Dropdown.Item onClick={() => setLayerType(LAYER_TYPES.K_WMS_BASE_LAYER.value)} value={LAYER_TYPES.K_WMS_BASE_LAYER.value}>{LAYER_TYPES.K_WMS_BASE_LAYER.label}</Dropdown.Item>
                    <Dropdown.Item onClick={() => setLayerType(LAYER_TYPES.K_WMS_TABLE.value)} value={LAYER_TYPES.K_WMS_TABLE.value}>{LAYER_TYPES.K_WMS_TABLE.label}</Dropdown.Item>
                  </DropdownButton>
                </Col>
              </Form.Group>
            )}
            {showLayerSettings && layerType === LAYER_TYPES.K_WMS_TABLE.value && (
              <Form.Group as={Row} controlId="configTable">
                <Form.Label column sm="2">
                  Table
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    as="select"
                    // onChange={setSelectedBaseTable}
                    onChange={e => {
                      setSelectedBaseTable(e.target.value);
                      updateSelectTableInfo(e.target.value);
                    }}
                    value={selectedBaseTable ? selectedBaseTable : ''}
                  >
                    {selectableTables}
                  </Form.Control>
                </Col>
              </Form.Group>
            )}
            {tableColumns && (
              <>
                {/* {showLayerSettings && (
                  <Form.Group as={Row} controlId="layerLabel">
                    <Form.Label column sm="2">
                      Label<span style={{color: 'red'}}> *</span>
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        value={label}
                        onChange={e => {
                          setLabel(e.target.value);
                        }}
                      />
                    </Col>
                  </Form.Group>
                )} */}
                {showLayerSettings && (
                <Form.Group as={Row} controlId="configDataType">
                  <Form.Label column sm="2">
                      Type/Style
                  </Form.Label>
                    {(<Col sm="4">
                    <ToggleButtonGroup
                      name="dataType"
                      style={{ width: '100%' }}
                      toggle
                    >
                      {Object.keys(DATA_TYPE_LABELS).map(type => {
                        return (
                          <ToggleButton
                            key={type}
                            type="radio"
                            name="radio"
                            variant={dataType === type ? 'primary' : 'light'}
                            value={type}
                            checked={dataType === type}
                            onChange={e => {
                              setDataType(prev => {
                                if (prev === 'point' && e.target.value === 'geo') {
                                  setHeatmapAggregation('COUNT');
                                  setHeatmapAttr('');
                                }
                                return e.target.value;
                              });
                            }}
                          >
                            {DATA_TYPE_LABELS[type]}
                          </ToggleButton>
                        );
                      })}
                    </ToggleButtonGroup>
                    </Col>)}
                  <Col sm="5">
                    <ToggleButtonGroup
                      name="renderType"
                      style={{ width: '100%' }}
                      toggle
                    >
                      {Object.keys(RENDER_TYPE_LABELS).map(type => {
                        return (
                          <ToggleButton
                            key={type}
                            type="radio"
                            name="radio"
                            variant={renderType === type ? 'primary' : 'light'}
                            value={type}
                            checked={renderType === type}
                            onChange={e => setRenderType(e.target.value)}
                          >
                            {RENDER_TYPE_LABELS[type]}
                          </ToggleButton>
                        );
                      })}
                    </ToggleButtonGroup>
                  </Col>
                </Form.Group>
                )}
                {showLayerSettings && dataType === 'point' && (
                  <Form.Group as={Row} controlId="configColumnLonLat">
                    <Form.Label column sm="2">
                      Lon/Lat
                    </Form.Label>
                    <Col sm="4">
                      <Form.Control
                        as="select"
                        onChange={e => setLongitude(e.target.value)}
                        value={longitude}
                      >
                        {numberColumns}
                      </Form.Control>
                    </Col>
                    <Col sm="4">
                      <Form.Control
                        as="select"
                        onChange={e => setLatitude(e.target.value)}
                        value={latitude}
                      >
                        {numberColumns}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                )}
                {showLayerSettings && dataType === 'geo' && (
                  <Form.Group as={Row} controlId="configColumnWkt">
                    <Form.Label column sm="2">
                      WKT
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        as="select"
                        onChange={e => {
                          setWkt(e.target.value);
                          //props.setWkt(e.target.value);
                        }}
                        value={wkt}
                      >
                        {geometryColumns}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                )}
                {showLayerSettings && renderType === 'heatmap' && (
                  <Form.Group as={Row} controlId="configColormap">
                    <Form.Label column sm="2">
                      Colormap
                    </Form.Label>
                    <Col sm="4">
                      <div style={{width: '400px'}}>
                        <DropdownButton
                          style={{width: "100%", minWidth: "200px", maxWidth: "400px" }}
                          id="colormap-dropdown"
                          title={colormap}
                          variant="light"
                        >
                          <div style={{maxHeight: '300px', overflowY: 'scroll'}} >
                            {colormaps}
                          </div>
                        </DropdownButton>
                      </div>
                    </Col>
                    <Col sm="4">
                      <div id='selected-colormap-gradient'></div>
                    </Col>
                    <Col sm="2">
                      <Button
                        onClick={handleResetColormap}
                        variant="light"
                        block
                      >
                        <ArrowCounterclockwise size={16} />
                      </Button>
                    </Col>
                  </Form.Group>
                )}
                {showLayerSettings && renderType === 'raster' && (
                  <Form.Group as={Row} controlId="configColors">
                    <Form.Label column sm="2">
                      Colors
                    </Form.Label>
                    <Col sm="4">
                      <Button
                        onClick={handleToggleFillPicker}
                        style={{
                          backgroundColor: `#${fillColor}`,
                          borderColor: `#${fillColor}`,
                        }}
                        block
                      >
                        Fill/Point
                      </Button>
                      {showFillPicker ? (
                        <div style={popover}>
                          <div style={cover} onClick={handleToggleFillPicker} />
                          <ChromePicker
                            color={`#${fillColor}`}
                            onChangeComplete={handleSelectFill}
                            disableAlpha={true}
                          />
                        </div>
                      ) : null}
                    </Col>
                    {dataType === 'geo' ? (
                      <>
                        <Col sm="4">
                          <Button
                            onClick={handleToggleBorderPicker}
                            style={{
                              backgroundColor: `#${borderColor}`,
                              borderColor: `#${borderColor}`,
                            }}
                            block
                          >
                            Border/Line
                          </Button>
                          {showBorderPicker ? (
                            <div style={popover}>
                              <div
                                style={cover}
                                onClick={handleToggleBorderPicker}
                              />
                              <ChromePicker
                                color={`#${borderColor}`}
                                onChangeComplete={handleSelectBorder}
                                disableAlpha={true}
                              />
                            </div>
                          ) : null}
                        </Col>
                        <Col sm="2">
                          <Button
                            onClick={handleResetCustomColors}
                            variant="light"
                            block
                          >
                            <ArrowCounterclockwise size={16} />
                          </Button>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col sm="4">&nbsp;</Col>
                        <Col sm="2">
                          <Button
                            onClick={handleResetCustomColors}
                            variant="light"
                            block
                          >
                            <ArrowCounterclockwise size={16} />
                          </Button>
                        </Col>
                      </>
                    )}
                  </Form.Group>
                )}
                {showLayerSettings && renderType === 'heatmap' && (
                  <div>
                    <Form.Group as={Row} controlId="configBlurRadius">
                      <Form.Label column sm="2">
                        Blur Size
                      </Form.Label>
                      <Col sm="8">
                        <div style={{ padding: '6px 0px' }}>
                          <Form.Control
                            type="range" min="0" max="20"
                            onChange={e => setBlurRadius(e.target.value)}
                            value={blurRadius}
                          />
                        </div>
                      </Col>
                      <Col sm="2">
                        {/* <Button
                          onClick={handleResetBlurRadius}
                          variant="light"
                          block
                        >
                          <ArrowCounterclockwise size={16} />
                        </Button> */}
                        <Form.Control 
                          key={'blurRadiusText'}
                          as="input"
                          min="0"
                          max="20"
                          size="sm"
                          value={blurRadius}
                          style={{ width: '60px' }}
                          onChange={e => setBlurRadius(e.target.value)}
                          />
                      </Col>
                    </Form.Group>

                    {dataType === 'point' && (
                    <Form.Group as={Row} controlId="configHeatmapAttr">
                      <Form.Label column sm="2">
                        Heatmap Behavior
                      </Form.Label>
                      <Col sm="8">
                        <div style={{ padding: '6px 0px' }}>
                          <Form.Control
                            type="text"
                            onChange={e => setHeatmapAttr(e.target.value.trim())}
                            value={heatmapAttr}
                            disabled={true}
                          />
                          <h5 style={{ fontSize: '10px', textAlign: 'left' }}>
                            <a href="https://docs.kinetica.com/7.1/api/rest/wms_rest/#wms-heatmap" target="_blank" rel="noreferrer">Heatmap Behaviors(VAL_ATTR)</a>
                          </h5>
                        </div>
                      </Col>

                      <div style={{ padding: '6px 0px', width: '15%' }}>
                        <Col xs="auto">
                            <Button style={{marginLeft: '0px', marginBottom: '5px' }} onClick={() => setIsHeatmapBehaviorEditorOpen(true)}>Edit</Button>
                        </Col>
                      </div>

                      {isHeatmapBehaviorEditorOpen && (
                        <div id="heatmap-behavior-editor" 
                            style={{ 
                              position: 'absolute',
                              zIndex: 2000, 
                              top: '0px', left: '0px', 
                              width: '100%', height: '100%', 
                              backgroundColor: 'rgba(0,0,0,0.5)' }}>
                          <CustomMeasureEditor
                            title="Heatmap Behavior Editor"
                            show={isHeatmapBehaviorEditorOpen}
                            showAggregationOptions={true}
                              columns={tableColumns}
                            fieldName={heatmapAggregation}
                            setFieldName={setHeatmapAggregation}
                            fieldContent={heatmapAttr}
                            setFieldContent={setHeatmapAttr}
                            clearField={() => {
                              setHeatmapAggregation('COUNT');
                              setHeatmapAttr('');
                            }}
                            documentation={{
                              description: 'Reference Documentation',
                              url: 'https://docs.kinetica.com/7.1/api/rest/wms_rest/#wms-heatmap' 
                            }}
                            close={() => setIsHeatmapBehaviorEditorOpen(false)}
                          />
                        </div>
                      )}

                    </Form.Group>
                    )}

                  </div>
                )}
                {showLayerSettings && (renderType === 'raster') && (
                  <Form.Group as={Row} controlId="configPointSize">
                    <Form.Label column sm="2">
                      Point Size
                    </Form.Label>
                    <Col sm="8">
                      <div style={{ padding: '6px 0px' }}>
                        <Form.Control
                          type="range" min="0" max="20"
                          onChange={e => /*props.*/ setPointSize(e.target.value)}
                          value={pointSize}
                        />
                      </div>
                    </Col>
                    <Col sm="2">
                      <Form.Control 
                        key={'pointSizeText'}
                        as="input"
                        min="0"
                        max="20"
                        size="sm"
                        value={pointSize}
                        onChange={e => /*props.*/ setPointSize(e.target.value)}
                        />
                    </Col>
                  </Form.Group>
                )}
                {showLayerSettings && (
                <Form.Group as={Row} controlId="configOpacity">
                  <Form.Label column sm="2">
                    Opacity
                  </Form.Label>
                  <Col sm="8">
                    <div style={{ padding: '6px 0px' }}>
                      <Form.Control
                        type="range"
                        onChange={e => setOpacity(parseInt(e.target.value))}
                        value={opacity}
                      />
                    </div>
                  </Col>
                  <Col sm="2">
                    <Form.Control 
                        key={'opacityText'}
                        as="input"
                        min="0"
                        max="20"
                        size="sm"
                        value={opacity}
                        onChange={e => setOpacity(parseInt(e.target.value)||'')}
                        />
                  </Col>
                  {/* <Col sm="2">
                    <Button onClick={handleResetOpacity} variant="light" block>
                      <ArrowCounterclockwise size={16} />
                    </Button>
                  </Col> */}

                  </Form.Group>
                )}
                {showLayerSettings && (
                  <Form.Group as={Row} controlId="configMaxZoom">
                    <Form.Label column sm="2">
                      Max Zoom
                    </Form.Label>
                    <Col sm="8">
                      <div style={{ padding: '6px 0px' }}>
                        <Form.Control
                          type="range"
                          min="0"
                          max="28"
                          onChange={e => setMaxZoom(parseInt(e.target.value))}
                          value={maxZoom}
                        />
                      </div>
                    </Col>
                    <Col sm="2">
                      <Form.Control
                        key={'maxZoomText'}
                        as="input"
                        min="0"
                        max="28"
                        size="sm"
                        value={maxZoom}
                        onChange={e => setMaxZoom(parseInt(e.target.value) || '')}
                      />
                    </Col>
                  </Form.Group>
                )}
                {showLayerSettings && (
                  <Form.Group as={Row} controlId="configMinZoom">
                    <Form.Label column sm="2">
                      Minimum Zoom
                    </Form.Label>
                    <Col sm="8">
                      <div style={{ padding: '6px 0px' }}>
                        <Form.Control
                          type="range"
                          min="0"
                          max="28"
                          onChange={e => setMinZoom(parseInt(e.target.value))}
                          value={minZoom}
                        />
                      </div>
                    </Col>
                    <Col sm="2">
                      <Form.Control
                        key={'minZoomText'}
                        as="input"
                        min="0"
                        max="28"
                        size="sm"
                        value={minZoom}
                        onChange={e => setMinZoom(parseInt(e.target.value) || '')}
                      />
                    </Col>
                </Form.Group>
                )}
                {showLayerSettings && renderType === 'cb_raster' && (
                  // Add stuff here
                  <ClassbreakStylingOptions
                    cbStyleOptions={cbStyleOptions}
                    allColumns={classBreakColumns} 
                    gpudb={props.gpudb}
                    handleStyleChange={handleStyleChanged}
                    cbColumnCache={cbColumnCache}
                    setCBColumnCache={setCBColumnCache}
                    setError={props.setError}
                    //view={ props.layer?.kineticaSettings?.view || `${datasource.table.schema}.${datasource.table.name}`}
                    view={layerType === LAYER_TYPES.K_WMS_TABLE.value ? selectedBaseTable : `${datasource.table.schema}.${datasource.table.name}`} 
                  />
                )}
                {showLayerSettings && (
                  <Form.Group as={Row} controlId="configDataType">
                    <Form.Label column sm="2">
                      Enable Popup
                    </Form.Label>
                    <Col sm="10">
                      <ToggleButtonGroup
                        name="dataType"
                        style={{ width: '100%' }}
                        toggle
                      >
                        <ToggleButton
                          key={'Yes'}
                          type="radio"
                          name="radio"
                          variant={enablePopup === true ? 'primary' : 'light'}
                          value={true}
                          checked={enablePopup === true}
                          onChange={e => {
                            setEnablePopup(e.target.value === 'true' ? true : false);
                          }}
                        >
                          Yes
                        </ToggleButton>
                        <ToggleButton
                          key={'No'}
                          type="radio"
                          name="radio"
                          variant={enablePopup === false ? 'primary' : 'light'}
                          value={false}
                          checked={enablePopup === 'false' ? false : true}
                          onChange={e => {
                            setEnablePopup(e.target.value === 'false' ? false : true);
                          }}
                        >
                          No
                        </ToggleButton>

                      </ToggleButtonGroup>
                    </Col>
                  </Form.Group>
                )}
                {showLayerSettings && enablePopup && (
                  <Form.Group as={Row} controlId="configDataType">
                    <Form.Label column sm="2">
                      Popup Mode
                    </Form.Label>
                    <Col sm="10">
                      <ToggleButtonGroup
                        name="dataType"
                        style={{ width: '100%' }}
                        toggle
                      >
                        {[POPUP_TYPE_GRID, POPUP_TYPE_TEMPLATE].map(type => {
                          return (
                            <ToggleButton
                              key={type}
                              type="radio"
                              name="radio"
                              variant={popupType === type ? 'primary' : 'light'}
                              value={type}
                              checked={popupType === type}
                              onChange={e => {
                                setPopupType(e.target.value);
                              }}
                            >
                              {type}
                            </ToggleButton>
                          );
                        })}
                      </ToggleButtonGroup>
                    </Col>
                  </Form.Group>
                )}

                {showLayerSettings && enablePopup && popupType === POPUP_TYPE_TEMPLATE && (
                  <Form.Group as={Row} controlId="configPopupTemplate">
                    <Form.Label column sm="2">
                      Popup Template
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        key={'popupTemplate'}
                        as="textarea"
                        rows={10}
                        value={popupTemplate}
                        onChange={e => setPopupTemplate(e.target.value)}
                      />
                      {/* <div style="color:"red>{{lon}} -- {{ lat}} </div> */}
                    </Col>
                  </Form.Group>
                )}
              </>
            )}
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" type="submit" 
            disabled={showLayerSettings && label.trim().length === 0}
            onClick={updateConfig} block>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MapSettings;
